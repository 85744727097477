import React from 'react';
import { Box, Icon, Stack, Stat, BoxProps } from 'pws-design-system/design-system';
import { isset } from '@aerisweather/javascript-sdk/dist/utils';

interface MeasureStatProps extends BoxProps {
    label: string;
    value: string | number;
    units?: string;
    icon?: string;
    size?: 'sm' | 'md' | 'lg';
}

const MeasureStat = ({ label, value, icon, size = 'md', ...rest }: MeasureStatProps) => (
    <Stack isInline {...rest}>
        {icon && (
            <Box width="32px" mr={2}>
                <Icon name={icon} size="30px" />
            </Box>
        )}
        <Stat
            size={size}
            value={isset(value) ? `${value}` : '--'}
            label={label}
        />
    </Stack>
);

export default MeasureStat;
