import React from 'react';
import { Box, Icon, List, Stack, Text, BoxProps } from 'pws-design-system/design-system';
import { formatDistance } from 'date-fns/esm';
import StationLogs from '../../../../models/stations/StationLogs';

const LogCell = ({
    log,
    style,
    onClick = () => {},
}: { log: any; style: any; onClick: () => void; }) => (
    <div style={style}>
        <Stack p={2} justify="space-between" align="center" cursor="pointer" isInline onClick={onClick}>
            <Stack spacing={1}>
                <Text
                    fontSize="md"
                    fontWeight="bold"
                    whiteSpace="nowrap"
                    style={{ textOverflow: 'ellipsis' }}
                >
                    {log.action}
                </Text>
                <Text
                    fontSize="xs"
                    color="text.base.tertiary"
                    whiteSpace="nowrap"
                    style={{ textOverflow: 'ellipsis' }}
                >
                    {formatDistance(new Date(), new Date(log.dateTimeISO))} ago by {log.user}
                </Text>
            </Stack>
            <Box width="20px">
                <Icon name="chevron-right" color="bg.500" size="24px" mr={-2} />
            </Box>
        </Stack>
        <Box height="1px" bg="bg.100" />
    </div>
);

interface LogsListViewProps extends BoxProps {
    logs: StationLogs;
    listSize?: { width: number, height: number };
    onSelectCell?: (index: number) => void;
}

const LogsListView = ({
    logs,
    listSize = { width: 300, height: 500 },
    onSelectCell = () => {},
    ...rest
}: LogsListViewProps) => (
    <Box {...rest}>
        <List
            cellProvider={({ index, style }) => (
                <LogCell log={logs.at(index)} onClick={() => onSelectCell(index)} style={style} />
            )}
            height={listSize.height}
            itemCount={logs.count}
            itemSize={57}
            width={listSize.width}
        />
    </Box>
);

export default LogsListView;
