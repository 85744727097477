import 'react-datepicker/dist/react-datepicker.css';
import React, { useState, useEffect, useRef } from 'react';
import { navigate } from 'gatsby';
import DatePicker from 'react-datepicker';
import format from 'date-fns/format';
import {
    Box, Button, Hide, IconButton, Stack, Text,
} from 'pws-design-system/design-system';
import useResizeAware from 'pws-design-system/design-system/hooks/useResizeAware';
import { isset } from '@aerisweather/javascript-sdk/dist/utils';
import { Helmet } from 'react-helmet';
import ObservationsTableView from './ObservationsTableView';
import ObservationsListView from '../observations/ObservationsListView';
import ObservationDetailView from './ObservationDetailView';
import { Station } from '../../../../models/stations';
import ActivityIndicator from '../../../ui/ActivityIndicator';
import useDataBinding from '../../../hooks/useDataBinding';
import Observations from '../../../../models/stations/Observations';
import useApi, { Api } from '../../../hooks/api/useApi';
import Downloader from '../../../ui/Downloader';

interface ObservationsViewProps {
    station: Station;
    date?: Date;
    periodIndex?: number;
}

const ObservationsView = ({
    station,
    date: initialDate = new Date(),
    periodIndex,
}: ObservationsViewProps) => {
    const [observations, setObservations] = useState<Observations>(new Observations());
    const [date, setDate] = useState<Date>(initialDate);
    const [utcOffset, setUtcOffset] = useState(null);
    const $showDateSelector = useDataBinding(false);
    const containerRef = useRef();
    const size = useResizeAware(containerRef);

    const { request: getObservations, result, isLoading } = useApi<Observations>((api: Api, data: any) => (
        api.routes.stations.getObservations(data.id, { fromDate: format(date, 'yyyy-MM-dd') })
    ));

    const handleSelectDate = (selectedDate: Date) => {
        setDate(selectedDate);
    };

    const handleSelectRow = (index: number) => {
        navigate(`/station/${station.id}/observations/${index}`);
    };

    useEffect(() => {
        getObservations({ id: station.id });
    }, [date]);

    useEffect(() => {
        if (result.success) {
            setObservations(result.object);
        }
    }, [result]);

    useEffect(() => {
        if (observations.count) {
            const ob = observations.at(0);
            setUtcOffset(ob.utcOffset);
        }
    }, [observations]);

    if (!isset(station)) {
        return (
            <ActivityIndicator />
        );
    }
    return periodIndex ? (
        <>
            <Helmet>
                <title>{station.displayName} - Observations | PWSWeather</title>
            </Helmet>
            <ObservationDetailView
                observation={observations.at(periodIndex)}
                date={date}
            />
        </>
    ) : (
        <>
            <Helmet>
                <title>{station.displayName} - Observations | PWSWeather</title>
            </Helmet>
            <div ref={containerRef}>
                <Hide tablet desktop>
                    <Stack my={3} align="center" justify="space-between" isInline>
                        <Text mt={3} variant="subheadline">{format(date, 'MMM d, yyyy')}</Text>
                        {utcOffset && <Text mt={2} variant="caption1">Time (UTC{utcOffset})</Text>}
                        <DatePicker
                            selected={date}
                            maxDate={new Date()}
                            popperPlacement="left"
                            onChange={(selectedDate: Date) => handleSelectDate(selectedDate)}
                            customInput={(
                                <IconButton
                                    icon="calendar"
                                    variant="ghost"
                                    onClick={() => $showDateSelector.setValue(!$showDateSelector.value)}
                                    aria-label="Select date"
                                />
                            )}
                            withPortal
                        />

                    </Stack>
                    <ObservationsListView
                        observations={observations}
                        listSize={{ width: size.width || 300, height: 500 }}
                        isLoading={isLoading}
                        onSelectCell={handleSelectRow}
                    />
                </Hide>
                <Hide mobile>
                    <Stack justify="space-between" align="center" isInline>
                        <Stack my={3} align="center" isInline>
                            <Text mt={2} variant="title1">{format(date, 'MMM d, yyyy')}</Text>
                            <Box>
                                <DatePicker
                                    selected={date}
                                    maxDate={new Date()}
                                    onChange={(selectedDate: Date) => handleSelectDate(selectedDate)}
                                    customInput={(
                                        <IconButton
                                            icon="calendar"
                                            variant="ghost"
                                            onClick={() => $showDateSelector.setValue(!$showDateSelector.value)}
                                            aria-label="Select date"
                                        />
                                    )}
                                />
                            </Box>
                            <Downloader
                                station={station}
                                triggerElement={(
                                    <Button
                                        size="xs"
                                        variantColor="dark"
                                    >
                                        Download
                                    </Button>
                                )}
                            />
                        </Stack>
                        {utcOffset && <Text mt={2} variant="caption1">Time (UTC{utcOffset})</Text>}
                    </Stack>
                    <ObservationsTableView
                        observations={observations}
                        isLoading={isLoading}
                    />
                </Hide>
            </div>
        </>
    );
};

export default ObservationsView;
