import { Box, Button, Flex, Form, FormField, PseudoBox, Stack, Text } from 'pws-design-system/design-system';
import React, { useEffect, useRef, useState } from 'react';
import DavisStations from '../../../../models/stations/DavisStations';
import useApi, { Api } from '../../../hooks/api/useApi';
import useDataBinding from '../../../hooks/useDataBinding';
import Dialog from '../../../ui/Dialog';
import Toast, { useToast } from '../../../ui/Toast';

const FatRadioButton = ({ children }: any) => {
    const [isActive, setIsActive] = useState(false);
    return (
        <Stack isInline mt={2}>
            <PseudoBox
                bg={isActive ? 'gray.300' : 'white'}
                boxShadow={isActive ? 'sm' : 'lg'}
                rounded="md"
                height="80px"
                width="200px"
                padding="0 40px"
                _hover={{ background: '#ddd' }}
                onClick={() => setIsActive(!isActive)}
            >
                <Flex cursor="pointer" alignItems="center" justifyContent="center" height="100%" width="100%">
                    {children}
                </Flex>
            </PseudoBox>
        </Stack>
    );
};

interface DavisApiKeyProps {
    $binding:any;
     onFetchStationsSuccess:any;
    $davisLinkBinding: any;
}

const DavisApiKeySetup = ({ $binding, onFetchStationsSuccess, $davisLinkBinding }: DavisApiKeyProps) => {
    const { request: getStations, result: getStationsResult } = useApi((api: Api, data: any) => {
        const route = api.routes.davis.getStations(data);
        return route;
    });

    const [publicKey, setPublicKey] = useState(null);
    const [privateKey, setPrivateKey] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast() as any;
    const formRef = useRef<any>();

    const showToast = (success: boolean, title: string, message: string) => {
        toast({
            duration: 5000,
            isClosable: true,
            render: () => (
                <Toast variant={success ? 'success' : 'error'} title={title} message={message} icon={success ? 'check-circle' : 'warning-2'} />
            ),
        });
    };

    useEffect(() => {
        if (getStationsResult.response) {
            if (getStationsResult.success) {
                const { value } = $davisLinkBinding;
                $davisLinkBinding.setValue({ ...value, publicKey, privateKey });
                onFetchStationsSuccess(getStationsResult.object);
                $binding.setValue(false);

            } else {
                showToast(false, 'Failed to find stations', getStationsResult.error ? getStationsResult.error.message : 'An error occured confirming these keys. Please try again.');
            }
        }
    }, [getStationsResult]);

    const onFormSubmit = async () => {
        setIsLoading(true);
        await getStations({ publicKey, privateKey });
        setIsLoading(false);
    };

    const onContinue = () => {
        formRef.current.submit();
    };

    return (
        <Dialog
            binding={$binding}
            title="Setup Your Station"
            zIndex={1}
            message="Enter your Davis API V2 Keys"
            buttons={[
                <Button
                    size="sm"
                    key="btn-cancel"
                    variant="outline"
                    variantColor="dark"
                    onClick={() => $binding.setValue(false)}
                >
                    Cancel
                </Button>,
                <Button
                    size="sm"
                    key="btn-confirm"
                    variant="solid"
                    disabled={!(publicKey && privateKey)}
                    onClick={() => onContinue()}
                    isLoading={isLoading}
                >
                    Continue
                </Button>,
            ]}
        >
            <Box mt={4} width="300px">
                <Form ref={formRef} onSubmit={onFormSubmit}>
                    <Stack spacing={4}>
                        <FormField
                            name="publicKey"
                            label="Public Key"
                            placeholder=""
                            onChange={(ev) => {
                                const target = ev.target as HTMLInputElement;
                                setPublicKey(target.value);
                            }}
                            field={{
                                type: 'text',
                                borderBottom: '1px solid black',
                                paddingLeft: '5px',
                                autocomplete: 'off',
                            }}
                        />
                        <FormField
                            name="privateKey"
                            label="Private Key"
                            placeholder=""
                            onChange={(ev: React.SyntheticEvent) => {
                                const target = ev.target as HTMLInputElement;
                                setPrivateKey(target.value);
                            }}
                            field={{
                                type: 'text',
                                borderBottom: '1px solid black',
                                paddingLeft: '5px',
                                autocomplete: 'off',
                            }}
                        />
                    </Stack>
                </Form>
            </Box>
        </Dialog>
    );
};

enum StationProvider {
    Davis = 'davis'
}

interface LinkStationDialogProps {
    $binding: any;
    onSelectStationProvider: any;
}

export const LinkStationDialog = ({ $binding, onSelectStationProvider }: LinkStationDialogProps) => {
    const [choiceSelected, setChoiceSelected] = useState(false);
    const [provider, setProvider] = useState(null);

    useEffect(() => {
        setChoiceSelected(false);
        setProvider(null);
    }, [$binding.value]);

    const onContinue = () => {
        onSelectStationProvider(provider);
        $binding.setValue(false);
    };

    const onProviderSelection = (newProvider: 'davis') => {
        if (newProvider === provider) {
            setChoiceSelected(false);
            setProvider(null);
            return;
        }
        setProvider(newProvider);
        setChoiceSelected(true);
    };

    return (
        <Dialog
            binding={$binding}
            title="Select Manufacturer"
            zIndex={1}
            message="To automatically link your station and have it send data to PWSWeather, select one of the supported manufacturers below. If your manufacturer is not listed, close this dialog and type in your hardware provider."
            buttons={[
                <Button
                    size="sm"
                    key="btn-cancel"
                    variant="outline"
                    variantColor="dark"
                    onClick={() => { setChoiceSelected(false); $binding.setValue(false); }}
                >
                    Cancel
                </Button>,
                <Button
                    size="sm"
                    key="btn-confirm"
                    variant={choiceSelected ? 'solid' : 'disabled'}
                    disabled={!choiceSelected}
                    onClick={() => onContinue()}
                >
                    Continue
                </Button>,
            ]}
        >
            <Box minWidth="300px">
                {/* <Text>Select your device manufacturer or service.</Text> */}
                <Box onClick={() => onProviderSelection(StationProvider.Davis)} mt={3}>
                    <FatRadioButton>
                        <Text color="gray.500" textAlign="center" fontWeight="bold">Davis Instruments</Text>
                    </FatRadioButton>
                </Box>
            </Box>
        </Dialog>
    );
};

interface DavisSelectStationProps {
    $binding: any;
    $davisStations: any;
    $davisLinkBinding: any;
}

const DavisSelectStation = ({ $binding, $davisStations, $davisLinkBinding }:DavisSelectStationProps) => {
    if (!$davisStations || !$davisStations.value) { return null; }

    const stations = $davisStations ? $davisStations.value.storedModels : [];
    const [selectedStationId, setSelectedStationId] = useState(stations[0].stationId.toString());

    const onContinue = () => {
        const selectedStation = stations.filter((station:any) => selectedStationId === station.stationId.toString())[0];
        $davisLinkBinding.setValue({
            ...$davisLinkBinding.value,
            linked: true,
            station: selectedStation,
        });
        $binding.setValue(false);
    };

    const onStationChange = (ev:any) => {
        setSelectedStationId(ev.target.value);
    };

    return (
        <Dialog
            binding={$binding}
            title="Select Your Device"
            zIndex={1}
            message="Select the device you would like to link to this PWSWeather station."
            buttons={[
                <Button
                    size="sm"
                    key="btn-cancel"
                    variant="outline"
                    variantColor="dark"
                    onClick={() => $binding.setValue(false)}
                >
                    Cancel
                </Button>,
                <Button
                    size="sm"
                    key="btn-confirm"
                    variant="solid"
                    onClick={() => onContinue()}
                >
                    Finish
                </Button>,
            ]}
        >
            <Box mt={4} minWidth="300px">
                <Stack spacing={2}>
                    {stations.map((station:any) => (
                        <Box>
                            <Stack isInline alignItems="center" spacing={2} onChange={onStationChange}>
                                <Box><input checked={selectedStationId === station.stationId.toString()} id={`selectedStation-${station.stationId}`} type="radio" name="selectedStation" value={station.stationId} /></Box>
                                <Box><label htmlFor={`selectedStation-${station.stationId}`}><Text cursor="pointer" fontWeight="bold">{station.name} (ID: {station.stationId})</Text></label></Box>
                            </Stack>
                        </Box>
                    ))}
                </Stack>
            </Box>
        </Dialog>
    );
};
interface DavisConfirmStationProps {
    $binding:any;
    $davisStations:any;
    $davisLinkBinding: any;
}

const DavisConfirmStation = ({ $binding, $davisStations, $davisLinkBinding }:DavisConfirmStationProps) => {
    if (!$davisStations || !$davisStations.value) { return null; }

    const stations = $davisStations.value;
    const onContinue = async () => {
        const { value } = $davisLinkBinding;
        $davisLinkBinding.setValue({
            ...value,
            station: stations.storedModels[0],
            linked: true,
        });
        $binding.setValue(false);
    };

    return (
        <Dialog
            binding={$binding}
            title="Confirm Your Station"
            zIndex={1}
            message="Confirm that the following Davis Instruments device is the one you want to link with this station."
            buttons={[
                <Button
                    size="sm"
                    key="btn-cancel"
                    variant="outline"
                    variantColor="dark"
                    onClick={() => $binding.setValue(false)}
                >
                    Cancel
                </Button>,
                <Button
                    size="sm"
                    key="btn-confirm"
                    variant="solid"
                    onClick={() => onContinue()}
                >
                    Confirm
                </Button>,
            ]}
        >
            <Box mt={4} minWidth="300px">
                <Stack spacing={2}>
                    {stations.toArray().map((station:any) => (
                        <Box>
                            <Stack isInline alignItems="center" spacing={2}>
                                <Box><Text fontWeight="bold">{station.name} (ID: {station.stationId})</Text></Box>
                            </Stack>
                        </Box>
                    ))}
                </Stack>
            </Box>
        </Dialog>
    );
};

// interface DavisNoUnassociatedStations {
//     $binding:any;
//     $davisStations:any;
// }

// const DavisNoUnassociatedStations = ({ $binding, $davisStations }: DavisNoUnassociatedStations) => {
//     if(!$davisStations || !$davisStations.value) { return null; }

//     const stations = $davisStations.value;

//     const onContinue = async () => {

//         // TODO: error handling
//     };

//     const getColumns = () => {
//         return [
//              {
//                 title: 'Id',
//                 field: 'stationId',
//                 align: 'left',
//                 formatter: (value: any): any => (
//                     <Text fontSize="md" isTruncated>{value}</Text>
//                 ),
//             },
//             {
//                 title: 'Name',
//                 field: 'name',
//                 align: 'left',
//                 formatter: (value: any): any => (
//                     <Text fontSize="md" isTruncated>{value}</Text>
//                 ),
//             },
//             {
//                 title: 'Profile',
//                 field: 'pwsId',
//                 align: 'left',
//                 formatter: (value: any): any => (
//                     <Link as={GatsbyLink} to={`/station/${value}/profile`} fontSize="md" isTruncated>Link</Link>
//                 ),
//             },
//         ];
//     };

//     return (
//         <Dialog
//             binding={$binding}
//             title="No Free Stations"
//             zIndex={1}
//             message="All of your Davis Devices are currently associated with stations on PWSWeather. Edit one of the PWS profiles below to make changes."
//             buttons={[
//                 <Button
//                     size="sm"
//                     key="btn-cancel"
//                     variant="outline"
//                     variantColor="dark"
//                     onClick={() => $binding.setValue(false)}
//                 >
//                     Cancel
//                 </Button>
//             ]}
//             >
//             <Box bg="white" color="black" mt={3}>
//                 <DataTable
//                     cssOverrides={css({
//                         '.table': {
//                             '.th': {
//                                 color: 'white'
//                             },
//                         },
//                     })}
//                     data={stations ? stations.toArray() : []}
//                     columns={(
//                         getColumns()
//                     )}
//                 />
//             </Box>
//         </Dialog>
//     );
// };

interface DavisNoStationsProps {
    $binding:any;
}

const DavisNoStations = ({ $binding }:DavisNoStationsProps) => (
    <Dialog
        binding={$binding}
        title="No devices found."
        zIndex={1}
        message="We were unable to find any devices associated with these keys."
        buttons={[
            <Button
                size="sm"
                key="btn-confirm"
                variant="solid"
                onClick={() => $binding.setValue(false)}
            >
                Close
            </Button>,
        ]}
    >
        <Box mt={4} width="300px">
            <Form onSubmit={() => {}}>
                <Stack spacing={4}>
                    We were unable to find any devices associated these keys.
                </Stack>
            </Form>
        </Box>
    </Dialog>
);

interface DialogsProps {
    $binding:any;
    $davisLinkBinding: any;
}

const Dialogs = ({ $binding, $davisLinkBinding }:DialogsProps) => {
    const $initialBinding = $binding;
    const $davisKeyBinding = useDataBinding(false);
    const $davisSelectStationBinding = useDataBinding(false);
    const $davisStations = useDataBinding(null);
    const $davisConfirmStation = useDataBinding(false);
    // const $davisNoUnassociatedStations = useDataBinding(false);
    const $davisNoStations = useDataBinding(false);

    const handleSelectStationProvider = (provider: StationProvider) => {
        if (provider === StationProvider.Davis) {
            $davisKeyBinding.setValue(true);
        }
    };

    const handleFetchStationsSuccess = (davisStations:DavisStations) => {
        if (davisStations.count === 0) {
            $davisNoStations.setValue(true);
            return;
        }

        $davisStations.setValue(davisStations);

        // if (davisStations.hasNoUnassociatedStations()) {
        //     $davisNoUnassociatedStations.setValue(true)
        //     return;
        // }

        if (davisStations.count === 1) {
            $davisConfirmStation.setValue(true);
            return;
        }

        $davisSelectStationBinding.setValue(true);

    };

    return (
        <>
            <LinkStationDialog $binding={$initialBinding} onSelectStationProvider={handleSelectStationProvider} />
            <DavisApiKeySetup $binding={$davisKeyBinding} $davisLinkBinding={$davisLinkBinding} onFetchStationsSuccess={handleFetchStationsSuccess} />
            <DavisSelectStation $binding={$davisSelectStationBinding} $davisStations={$davisStations} $davisLinkBinding={$davisLinkBinding} />
            <DavisConfirmStation $binding={$davisConfirmStation} $davisStations={$davisStations} $davisLinkBinding={$davisLinkBinding} />
            {/* <DavisNoUnassociatedStations $binding={$davisNoUnassociatedStations} $davisStations={$davisStations} /> */}
            <DavisNoStations $binding={$davisNoStations} />
        </>
    );
};
export default Dialogs;
