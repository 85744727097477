import React from 'react';
import { createPortal } from 'react-dom';
import { Box, Layer, Stack, Text, BoxProps, Spinner, Absolute } from 'pws-design-system/design-system';

export interface DialogProps extends BoxProps {
    title?: string;
    message: string;
    children?: any;
    buttons?: any;
    binding?: any;
    showActivity?: boolean;
    zIndex?: number;
    onClose?: () => void;
}

const Dialog = ({
    title,
    message,
    children,
    buttons,
    binding,
    showActivity = false,
    zIndex,
    ...rest
}: DialogProps) => {
    let content = (
        <Layer
            maxWidth={['100%', null, '500px']}
            animation="slide"
            animationDirection="up"
            isActive={binding.value}
            onClose={() => binding.setValue(false)}
            bg="bg.reversed.primary"
            color="text.reversed.primary"
            p={[3, null, 4]}
            rounded="xl"
            {...rest}
        >
            {showActivity ? (
                <Box width="100px" height="100px" position="relative">
                    <Absolute anchor="center">
                        <Spinner size="lg" />
                    </Absolute>
                </Box>
            ) : (
                <Box>
                    {title && (
                        <Text variant="subheadline" fontWeight="bold">
                            {title}
                        </Text>
                    )}
                    <Text variant="callout">{message}</Text>
                    {children}
                    <Stack mt={4} justify="flex-end" isInline>
                        {Array.isArray(buttons) ? buttons.map((button) => button) : buttons}
                    </Stack>
                </Box>
            )}
        </Layer>
    );

    if (typeof zIndex === 'number') {
        content = (
            <Box position="relative" zIndex={zIndex}>
                {content}
            </Box>
        );
    }

    return (
        createPortal(
            content,
            document.querySelector('body'),
        )
    );
};

export default Dialog;
