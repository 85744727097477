import React from 'react';
import { Box, BoxProps, Button, Text } from 'pws-design-system/design-system';
import { format } from 'date-fns/esm';
import { navigate } from '@reach/router';
import Card from '../../../ui/Card';

interface LogDetailViewProps extends BoxProps {
    log: any;
}

const mockLog = {
    dateTimeISO: '2019-09-23T13:59:34Z',
    action: 'Station confidence lowered',
    user: 'Admin',
    notes: 'Station confidence lowered due to bad observation data being sent, including temperature, winds and precipitation. Station confidence lowered due to bad observation data being sent, including temperature, winds and precipitation.',
};

const LogDetailView = ({ log = mockLog, ...rest }: LogDetailViewProps) => (
    <Box pt={2}>
        <Button
            size="xs"
            variantColor="dark"
            leftIcon="arrow-left"
            onClick={() => navigate(-1)}
        >
            All Logs
        </Button>
        <Card mt={3} {...rest}>
            <Text variant="subheadline" mb={0}>{log.action}</Text>
            <Text variant="label" fontSize="xs" color="text.base.tertiary">
                {format(new Date(log.dateTimeISO), 'h:mm a, M/d/yyyy')} by {log.user}
            </Text>
            <Text mt={4} lineHeight="tall">
                {log.notes || 'Notes were not provided for this action.'}
            </Text>
        </Card>
    </Box>
);

export default LogDetailView;
