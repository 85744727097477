import React, { useState } from 'react';
import ActivityIndicator from './ActivityIndicator';

export enum RenderState {
    Initial = 'initial',
    Loading = 'loading',
    Error = 'error',
    Content = 'content',
    Empty = 'empty',
    NotFound = 'notfound'
}

export const useRenderState = (initialValue: RenderState = RenderState.Initial) => {
    const [value, setValue] = useState<RenderState>(initialValue);
    return { renderState: value, setRenderState: setValue };
};

export interface StatefulRendererProps {
    state: RenderState;
    loadingElement?: any;
    errorElement?: any;
    emptyElement?: any;
    notFoundElement?: any;
    children?: any;
}

const StatefulRenderer = ({
    state = RenderState.Empty,
    emptyElement = <></>,
    loadingElement = <ActivityIndicator />,
    errorElement,
    notFoundElement = <>The requested item was not found.</>,
    children,
}: StatefulRendererProps) => {
    if (state === RenderState.Loading) {
        return loadingElement;
    }
    if (state === RenderState.Error) {
        return errorElement;
    }
    if (state === RenderState.NotFound) {
        return notFoundElement;
    }
    if (state === RenderState.Content) {
        return children;
    }
    return emptyElement;
};

export default StatefulRenderer;
