import React from 'react';
import { Button, Hide, Icon, Stack } from 'pws-design-system/design-system';

interface EditorToolbarProps {
    modelName?: string;
    enableDelete?: boolean;
    isEditing: boolean;
    isSaving: boolean;
    isInlineMobile?: boolean;
    extraMobileElements?: any;
    onSave?: () => void;
    onDelete?: () => void;
}

const EditorToolbar = ({
    modelName = '',
    enableDelete = true,
    isEditing = false,
    isSaving = false,
    isInlineMobile = true,
    extraMobileElements,
    onSave,
    onDelete,
}: EditorToolbarProps) => (
    <>
        <Hide mobile>
            <Stack spacing={3} align="center" isInline>
                {isEditing && enableDelete && (
                    <Button variantColor="dark" width="44px" height="44px" pl={0} pr={0} aria-label="Delete {modelName.toLowerCase()}" onClick={onDelete}>
                        <Icon name="delete" size="18px" />
                    </Button>
                )}
                <Button
                    variantColor="brand-blue"
                    onClick={e => {
                        e.preventDefault();
                        onSave(e);
                    }}
                    isLoading={isSaving}
                >
                    {isEditing ? 'Update' : 'Save'} {modelName}
                </Button>
            </Stack>
        </Hide>
        <Hide tablet desktop>
            <Stack spacing={3} align="center" isInline={isInlineMobile}>
                {isEditing && enableDelete && (
                    <Button
                        flexBasis={isInlineMobile ? '50%' : '100%'}
                        width={isInlineMobile ? 'auto' : 'full'}
                        variantColor="dark"
                        aria-label={`Delete ${modelName.toLowerCase()}`}
                        onClick={onDelete}
                    >
                        Delete
                    </Button>
                )}
                <Button
                    flexBasis={isInlineMobile ? '50%' : '100%'}
                    width={isInlineMobile ? 'auto' : 'full'}
                    variantColor="brand-blue"
                    onClick={onSave}
                    isLoading={isSaving}
                >
                    {isEditing ? 'Update' : 'Save'}
                </Button>
                {extraMobileElements}
            </Stack>
        </Hide>
    </>
);

export default EditorToolbar;
