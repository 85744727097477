import React from 'react';
import { DataTableOpts } from 'pws-design-system/design-system';
import { isset } from '@aerisweather/javascript-sdk/dist/utils';
import format from 'date-fns/format';
import DataTable from '../../../ui/DataTable';
import Observations from '../../../../models/stations/Observations';

interface ObservationsTableViewProps extends DataTableOpts {
    observations: Observations;
    isLoading?: boolean;
}

const ObservationsTableView = ({
    observations,
    isLoading,
    ...rest
}: ObservationsTableViewProps) => (
    <DataTable
        data={observations.toArray()}
        columns={[
            {
                id: 'date',
                title: 'Date',
                field: 'dateTime',
                align: 'left',
                width: 100,
                formatter: (value: any) => {
                    if (isset(value)) {
                        return `${format(
                            new Date(value.slice(0, value.length - 6)),
                            'MMM d, yyyy',
                        )}`;
                    }
                    return 'N/A';
                },
            },
            {
                id: 'time',
                title: 'Time',
                field: 'dateTime',
                align: 'left',
                width: 100,
                formatter: (value: any) => {
                    if (isset(value)) {
                        return `${format(
                            new Date(value.slice(0, value.length - 6)),
                            'h:mm a',
                        )}`;
                    }
                    return 'N/A';
                },
            },
            {
                title: 'Temp',
                field: 'tempFormatted',
                width: 100,
            },
            {
                title: 'Dew Pt',
                field: 'dewpointFormatted',
                width: 100,
            },
            {
                title: 'Humidity',
                field: 'humidity',
                width: 100,
                formatter: (value: any) => (isset(value) ? `${value}%` : '--'),
            },
            {
                id: 'winds',
                title: 'Winds',
                columns: [
                    {
                        title: 'Speed',
                        field: 'windSpeedFormatted',
                        width: 100,
                    },
                    {
                        title: 'Direction',
                        field: 'windDirection',
                        width: 100,
                    },
                ],
                width: 200,
            },
            {
                title: 'Pressure',
                field: 'pressureFormatted',
                width: 100,
            },
            {
                title: 'Precip',
                field: 'precipFormatted',
                width: 100,
            },
        ]}
        itemSize={40}
        fillAdjust={-98}
        virtualize
        fill
        isLoading={isLoading}
        {...rest}
    />
);

export default ObservationsTableView;
