import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, FormField, Stack, BoxProps } from 'pws-design-system/design-system';
import { states, countries, ucwords } from '@aerisweather/javascript-sdk/dist/utils/strings';
import useApi, { Api } from '../../../hooks/api/useApi';
import Place from '../../../../models/Place';
import { get } from "@aerisweather/javascript-sdk/dist/utils";

interface AddressEditorProps extends BoxProps {
    data?: any;
    onGeocode?: (coordinate: { lat: number, lon: number }) => void;
}

const AddressEditor = ({
    data = {},
    onGeocode = () => {},
    ...rest
}: AddressEditorProps) => {
    const { request: geocode, result, isLoading } = useApi<Place>((api: Api, address: string) => (
        api.routes.location.geocode(address)
    ));

    const [country, setCountry] = useState(get(data, 'country') || 'US');
    const [state, setState] = useState(get(data, 'state') || '');

    const fieldStates = states().us;
    const fieldCountries = countries();

    const handleGeocode = () => {
        const { address, city, state, country } = data;
        geocode(`${address} ${city}, ${state}, ${country}`);
    };

    const handleCountryChange = (event: React.SyntheticEvent) => {
        const { value } = event.target as HTMLSelectElement;
        if (value !== 'US') {
            setState('');
        }
        setCountry(value);
    };

    const handleStateChange = (event: React.SyntheticEvent) => {
        const { value } = event.target as HTMLSelectElement;
        setState(value);
    };

    useEffect(() => {
        if (result.success && result.object) {
            onGeocode({
                lat: result.object.latitude,
                lon: result.object.longitude,
            });
        }
    }, [result]);

    return (
        <Box {...rest}>
            <Stack spacing={4}>
                <FormField
                    type="select"
                    name="location.country"
                    label="Country"
                    onChange={handleCountryChange}
                    value={country}
                    field={{
                        placeholder: "Select a country...",
                        variant: "flushed",
                        options: Object.keys(fieldCountries)
                            .sort((leftKey, rightKey) => {
                                const left = fieldCountries[leftKey];
                                const right = fieldCountries[rightKey];
                                return left < right ? -1 : 1;
                            })
                            .reduce((prev: any, current: string) => {
                                prev[current.toUpperCase()] = ucwords(fieldCountries[current]); // eslint-disable-line no-param-reassign
                                return prev;
                            }, {}),
                    }}
                />
                <FormField
                    type="input"
                    name="location.address"
                    label="Physical Address"
                    field={{
                        variant: "flushed",
                    }}
                />
                <FormField
                    type="input"
                    name="location.city"
                    label="City"
                    field={{
                        variant: "flushed",
                    }}
                />
                <FormField
                    type={country === 'US' ? 'select' : 'input'}
                    name="location.state"
                    label={`${country === 'US' ? 'State' : 'State/Province/Region'}`}
                    value={state}
                    key={`forcerender_${country}`}
                    onChange={handleStateChange}
                    field={{
                        placeholder: `${country === 'US' ? 'Select a state...' : ''}`,
                        variant: "flushed",
                        options: Object.keys(fieldStates).reduce((prev: any, current: string) => {
                            prev[current.toUpperCase()] = ucwords(fieldStates[current]); // eslint-disable-line no-param-reassign
                            return prev;
                        }, {}),
                    }}
                />
                <FormField
                    type="input"
                    name="location.postalCode"
                    label="Zip Code"
                    field={{
                        variant: "flushed",
                    }}
                />
            </Stack>
            <Flex justify="flex-end">
                <Button
                    mt="30px"
                    size="xs"
                    variantColor="dark"
                    isLoading={isLoading}
                    onClick={handleGeocode}
                >
                    Geocode Address
                </Button>
            </Flex>
        </Box>
    );
};

export default AddressEditor;
