import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
    Button,
    Flex,
    Popover,
    Stack,
    Text,
    TextInput,
    BoxProps,
    SegmentedControl,
} from 'pws-design-system/design-system';
import { css } from '@emotion/core';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays';
import differenceInDays from 'date-fns/differenceInDays';
import { get } from '@aerisweather/javascript-sdk/dist/utils';
import useDataBinding from '../hooks/useDataBinding';
import useApi, { Api } from '../hooks/api/useApi';
import FileDownload from '../../models/FileDownload';
import { Station } from '../../models/stations';
import Toast, { useToast } from './Toast';
import ActivityIndicator from './ActivityIndicator';

enum DownloadAction {
    Inline = 'inline',
    Download = 'download',
    Email = 'email'
}

interface DownloaderProps extends BoxProps {
    station: Station;
    triggerElement?: any;
}

const Downloader = ({
    station,
    triggerElement,
    ...rest
}: DownloaderProps) => {
    const $fromDate = useDataBinding(new Date());
    const $toDate = useDataBinding(new Date());
    const $showFromSelector = useDataBinding(false);
    const $showToSelector = useDataBinding(false);
    const $isDownloading = useDataBinding(false);
    const $format = useDataBinding('json');
    const [action, setAction] = useState(DownloadAction.Download);
    const $downloadToken = useDataBinding();
    const [maxDate, setMaxDate] = useState(new Date());
    const linkRef = useRef<HTMLAnchorElement>(null);
    const toast = useToast();
    const progressTimer = useRef<number>();

    // const $activeDownload = useDataBinding<Date>(null);
    // const downloads = useDownloads();

    const { request: download, result, isLoading, isInitialLoad } = useApi<FileDownload>(
        (api: Api, data: any) => api.routes.stations.downloadObservations(data.id, {
            fromDate: format($fromDate.value, 'yyyy-MM-dd'),
            toDate: format($toDate.value, 'yyyy-MM-dd'),
            action:
                    differenceInDays(new Date($toDate.value), new Date($fromDate.value)) < 1
                        ? DownloadAction.Download
                        : DownloadAction.Email,
            format: $format.value,
        }),
    );

    const showToast = (success: boolean, title: string, message: string) => {
        toast({
            duration: 5000,
            isClosable: true,
            render: () => (
                <Toast variant={success ? 'success' : 'error'} title={title} message={message} icon="check-circle" />
            ),
        });
    };

    const handleSelectDate = (key: string, date: Date) => {
        if (key === 'from') {
            $fromDate.setValue(date);
        } else {
            $toDate.setValue(date);
        }
    };

    const handleDownload = () => {
        download({ id: station.id });
    };

    useEffect(() => {
        const noData = get(result, 'error.code') === 'warn_no_data';
        if (noData) {
            showToast(true, 'No Observations', 'There were no observations for the date range selected.');
        } else if (result.success) {
            const { fromDate, toDate } = result.params;
            if (differenceInDays(new Date(toDate), new Date(fromDate)) < 1) {
                linkRef.current.click();
                showToast(true, 'Download Started', 'Your download has begun.');
            } else {
                showToast(
                    true,
                    'Download Queued',
                    'Your log is being generated. A link will be sent to your email account when it is complete.',
                );
            }
        } else {
            if (isInitialLoad) {
                return;
            }
            showToast(false, 'Download failed', `Download request failed - ${result.error.message}`);
        }
    }, [result]);

    return (
        <>
            <Popover.Container placement="auto" closeOnBlur usePortal>
                <Popover.Trigger>{triggerElement}</Popover.Trigger>
                <Popover.Content
                    width="500px"
                    bg="bg.200"
                    border={0}
                    borderRadius="xl"
                    zIndex={500}
                    css={css`
                        width: "500px";
                        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.04), 0 4px 16px 0 rgba(0, 0, 0, 0.16);
                        border-radius: 0.75em;
                        :focus {
                            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.04),
                                0 4px 16px 0 rgba(0, 0, 0, 0.16);
                        }
                    `}
                >
                    <Popover.Arrow />
                    <Popover.CloseButton />
                    <Popover.Body p={3}>
                        <Text variant="title2" mb={2}>
                            Download Data
                        </Text>
                        {$isDownloading.value && (
                            <ActivityIndicator
                                size="lg"
                                minHeight="100px"
                                message="Preparing download..."
                            />
                        )}
                        {$isDownloading.value === false && (
                            <>
                                <Text variant="caption1" color="text.base.tertiary">
                                    Select the date range you want to download data for.
                                </Text>
                                <Stack my={3} align="center" isInline>
                                    <DatePicker
                                        selected={$fromDate.value}
                                        maxDate={maxDate}
                                        startDate={$fromDate.value}
                                        endDate={$toDate.value}
                                        selectsStart
                                        onChange={(selectedDate: Date) => handleSelectDate('from', selectedDate)}
                                        customInput={(
                                            <TextInput
                                                width="100px"
                                                size="md"
                                                variant="flushed"
                                                placeholder="From date..."
                                                onFocus={() => $showFromSelector.setValue(
                                                    !$showFromSelector.value,
                                                )}
                                            />
                                        )}
                                    />
                                    <Text mx={3}>to</Text>
                                    <DatePicker
                                        selected={$toDate.value}
                                        minDate={$fromDate.value}
                                        maxDate={new Date()}
                                        startDate={$fromDate.value}
                                        endDate={$toDate.value}
                                        selectsEnd
                                        onChange={(selectedDate: Date) => handleSelectDate('to', selectedDate)}
                                        customInput={(
                                            <TextInput
                                                width="100px"
                                                size="md"
                                                variant="flushed"
                                                placeholder="To date..."
                                                onFocus={() => $showToSelector.setValue(!$showToSelector.value)}
                                            />
                                        )}
                                    />
                                </Stack>
                                <Text mb={3} variant="caption1" color="text.base.tertiary">
                                    Select the data format you&apos;d like the file to be in.
                                </Text>
                                <SegmentedControl
                                    defaultValue={$format.value}
                                    size="xs"
                                    p="3px"
                                    variantColor="light"
                                    bg="brand.gray.100"
                                    spacing={0}
                                    variant="solid"
                                    selectedVariant="outline-fill"
                                    selectedVariantColor="dark"
                                    rounded="full"
                                    width="100px"
                                    onChange={(format: string) => $format.setValue(format)}
                                >
                                    <Button
                                        value="json"
                                        color={$format.value === 'json' ? 'white' : 'black'}
                                    >
                                        JSON
                                    </Button>
                                    <Button
                                        value="csv"
                                        color={$format.value === 'csv' ? 'white' : 'black'}
                                    >
                                        CSV
                                    </Button>
                                </SegmentedControl>
                                <Flex justify="flex-end">
                                    <Button
                                        mt={1}
                                        size="sm"
                                        variant="solid"
                                        variantColor="brand-blue"
                                        aria-label="Download Data"
                                        onClick={handleDownload}
                                        disabled={isLoading}
                                    >
                                        Download
                                    </Button>
                                </Flex>
                            </>
                        )}
                    </Popover.Body>
                </Popover.Content>
            </Popover.Container>
            {
                result.data?.url
                    ? <a hidden ref={linkRef} href={result.data.url}>asdf</a>
                    : null
            }

        </>
    );
};

export default Downloader;
