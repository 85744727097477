import React from 'react';
import { Box, Icon, List, Stack, Text, BoxProps } from 'pws-design-system/design-system';
import format from 'date-fns/format';
import DataList from '../../../ui/DataList';
import Observations from '../../../../models/stations/Observations';

const ObservationCell = ({
    observation,
    style,
    onClick = () => {},
}: { observation: any; style: any; onClick: () => void; }) => (
    <div style={style}>
        <Stack p={2} justify="space-between" align="center" cursor="pointer" isInline onClick={onClick}>
            <Stack flexGrow={1} justify="space-between" align="center" isInline>
                <Text minWidth="100px" flexGrow={1} fontWeight="bold">
                    {observation.timestamp ? format(new Date(observation.timestamp * 1000), 'h:mm a') : ''}
                </Text>
                <Text minWidth="80px" flexGrow={1} textAlign="center">
                    {observation.tempFormatted}
                </Text>
                <Box minWidth="100px" flexGrow={1} textAlign="center">
                    <Text>{observation.windDirection}</Text>
                    <Text>{observation.windSpeedFormatted}</Text>
                </Box>
            </Stack>
            <Box width="20px">
                <Icon name="chevron-right" color="bg.500" size="24px" mr={-2} />
            </Box>
        </Stack>
        <Box height="1px" bg="bg.100" />
    </div>
);

interface ObservationsListViewProps extends BoxProps {
    observations: Observations;
    isLoading?: boolean;
    listSize?: { width: number, height: number };
    onSelectCell?: (index: number) => void;
}

const ObservationsListView = ({
    observations,
    isLoading = false,
    listSize = { width: 300, height: 500 },
    onSelectCell = () => {},
    ...rest
}: ObservationsListViewProps) => (
    <Box {...rest}>
        <Stack px={2} py={1} justify="space-between" align="center" bg="bg.300" isInline>
            <Stack
                flexGrow={1}
                justify="space-between"
                align="center"
                fontSize="xs"
                fontFamily="label"
                textAlign="center"
                textTransform="uppercase"
                letterSpacing="widest"
                isInline
            >
                <Text minWidth="100px" flexGrow={1} fontSize="xs" fontWeight="bold" textAlign="left">Time</Text>
                <Text minWidth="80px" flexGrow={1} fontSize="xs" fontWeight="bold">Temp</Text>
                <Text minWidth="100px" flexGrow={1} fontSize="xs" fontWeight="bold">Winds</Text>
            </Stack>
            <Box width="20px" />
        </Stack>
        <DataList
            enablePagination={false}
            isLoading={isLoading}
        >
            <List
                cellProvider={({ index, style }) => (
                    <ObservationCell observation={observations.at(index)} onClick={() => onSelectCell(index)} style={style} />
                )}
                height={listSize.height}
                itemCount={observations.count}
                itemSize={57}
                width={listSize.width}
            />
        </DataList>
    </Box>
);

export default ObservationsListView;
