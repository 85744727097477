import { useState, useEffect } from 'react';

const useBeforeUnload = (message: string = null) => {
    const [trigger, setTrigger] = useState(false);

    const handleUnload = () => {
        console.log('handleUnload', 'trigger', trigger);
        if (trigger) {
            return message;
        }
        return undefined;
    };

    useEffect(() => {
        window.addEventListener('beforeunload', handleUnload);
        return () => window.removeEventListener('beforeunload', handleUnload);
    }, []);

    return (value: boolean) => setTrigger(value);
};

export default useBeforeUnload;
