import { cloneDeep, get, isset, set } from '@aerisweather/javascript-sdk/dist/utils';
import { Router, useLocation } from '@reach/router';
import { Link, navigate, withPrefix } from 'gatsby';
import {
    Avatar, Box, Button, ExpandingMenu, Hide, Stack, Text,
} from 'pws-design-system/design-system';
import React, { useEffect, useRef, useState } from 'react';
import useAuth from '../../context/auth';
import { Station } from '../../models/stations';
import { MeasureType } from '../../types/enums';
import { isMetricValue } from '../../utils/units';
import useApi, { Api, ApiError } from '../hooks/api/useApi';
import { store as unitStore } from '../hooks/store/useUnitStore';
import useDataBinding from '../hooks/useDataBinding';
import { useMobileQuery } from '../hooks/useMediaQuery';
import ContentPanelLayout from '../layouts/ContentPanelLayout';
import DashboardLayout from '../layouts/DashboardLayout';
import ProtectedResource from '../ProtectedResource';
import Dialog from '../ui/Dialog';
import EditorToolbar from '../ui/EditorToolbar';
import StatefulRenderer, { RenderState, useRenderState } from '../ui/StatefulRenderer';
import StatusBadge from '../ui/StatusBadge';
import Toast, { useToast } from '../ui/Toast';
import AdminView from '../views/stations/admin/AdminView';
import LogsView from '../views/stations/logs/LogsView';
import ObservationsView from '../views/stations/observations/ObservationsView';
import ProfileView from '../views/stations/profile/ProfileView';

enum ViewType {
    profile = 'profile',
    observations = 'observations',
    logs = 'logs',
    admin = 'admin'
}

const parsePath = (path: string): any => {
    const parts = path.replace(/^.*\/station\//, '').split('/');
    return {
        id: parts[0],
        view: parts[1],
        detail: parts[2],
    };
};

const toViewType = (str: string): ViewType => {
    if (str === ViewType.profile) {
        return ViewType.profile;
    }
    if (str === ViewType.observations) {
        return ViewType.observations;
    }
    if (str === ViewType.logs) {
        return ViewType.logs;
    }
    if (str === ViewType.admin) {
        return ViewType.admin;
    }
    return null;
};

const SectionTab = ({
    type, currentType, onClick, ...rest
}: any) => (
    <Button
        size="sm"
        variantColor="default"
        variant={(currentType === type) ? 'solid' : 'ghost'}
        onClick={() => onClick(type)}
        {...rest}
    />
);

interface StationPageProps {
    stationId: string;
}

const StationPageContent = ({ station }: StationPageProps & any) => {
    const stationData: any = station ? station.toObject() : { location: {} };
    const session = useAuth();
    const [currentView, setCurrentView] = useState<ViewType>(null);
    const [showToolbar, setShowToolbar] = useState(true);
    const [recordId, setRecordId] = useState(null);
    const [isSaved, setIsSaved] = useState(isset(station));

    const $status = useDataBinding(get(stationData, 'status'));
    const $confirmDelete = useDataBinding(false);

    const { request: saveStation, result: saveResult, isLoading: isSaving } = useApi<Station>((api: Api, data: any) => {
        if (isset(station)) {
            return api.routes.stations.updateStation(station.id, data);
        }
        return api.routes.stations.addStation(data);

    });

    const { request: deleteStation, result: deleteResult, isLoading: isDeleting } = useApi<Station>((api: Api) => (
        api.routes.stations.deleteStation(station.id)
    ));

    const location = useLocation();
    const toast = useToast();
    const formRef = useRef<any>();

    const isEditing = isset(station);
    const isMoblie = useMobileQuery();

    const handleTabSelect = (type: ViewType) => {
        navigate(`/station/${station.id}/${type}`);
    };

    const showToast = (success: boolean, title: string, message: string) => {
        toast({
            duration: 5000,
            isClosable: true,
            render: () => (
                <Toast variant={success ? 'success' : 'error'} title={title} message={message} icon="check-circle" />
            ),
        });
    };

    const handleSave = () => {
        if (formRef.current) {
            formRef.current.form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
        }
    };

    const handleFormSubmit = async (data: any) => {
        const payload = cloneDeep(data);
        // convert elevation to meters if user unit preference is Imperial
        const elev = get(payload, 'location.elevation') || get(payload, 'location.elev');
        if (Number.isNaN(elev) === false && isMetricValue(unitStore.get(MeasureType.Height)) === false) {
            set(payload, 'location.elevationFT', Number(elev));
        } else {
            set(payload, 'location.elevationFT', Number(elev) * 3.28084);
        }
        setIsSaved(false);
        saveStation(payload);
    };

    const handleDeleteAction = () => {
        $confirmDelete.setValue(true);
    };

    const handleDelete = () => {
        deleteStation();
    };

    useEffect(() => {
        if (saveResult.response) {
            if (saveResult.success) {
                showToast(true, `Station ${isEditing ? 'updated' : 'added'}`, `Station was ${isEditing ? 'updated' : 'added'} successfully.`);
                setIsSaved(true);
                if (isEditing === false) {
                    navigate('/stations');
                } else {
                    // update status info for existing station model
                    $status.setValue(saveResult.data.station.status);
                    station.set('status', saveResult.data.station.status);
                    station.set('confidence', saveResult.data.station.confidence);
                }
            } else {
                showToast(false, 'Station failed to save', saveResult.error ? saveResult.error.message : 'The station could not be saved at this time. Please try again.');
            }
        }

    }, [saveResult]);

    useEffect(() => {
        if (deleteResult.response) {
            if (deleteResult.success) {
                navigate('/stations');
                setTimeout(() => {
                    showToast(true, 'Station deleted', 'Station was deleted successfully.');
                }, 500);
            } else {
                showToast(false, 'Station delete failed', deleteResult.error ? deleteResult.error.message : 'The station could not be deleted at this time. Please try again.');
            }
        }
    }, [deleteResult]);

    useEffect(() => {
        if (isEditing) {
            const path = parsePath(location.pathname);
            path.view = path.view || ViewType.profile;
            setCurrentView(toViewType(path.view));
            setRecordId(path.detail);
        } else {
            setCurrentView(ViewType.profile);
        }
    }, [location]);

    useEffect(() => {
        setShowToolbar(currentView === ViewType.profile || currentView === ViewType.admin);
    }, [currentView]);

    return (
        <>
            <ContentPanelLayout
                title={isset(station) ? station.displayName : 'Add a station'}
                category="Station"
                rightElement={
                    isset(station) && (
                        <Stack>
                            <Box>
                                <StatusBadge value={$status.value} />
                            </Box>
                            {isset(station.stationId) && (
                                <Text
                                    variant="label"
                                    fontSize="xs"
                                    color="text.base.tertiary"
                                >
                                    ID: {station.stationId}
                                </Text>
                            )}
                        </Stack>
                    )
                }
                toolbarElement={
                    showToolbar ? (
                        <EditorToolbar
                            modelName="Station"
                            isEditing={isset(station)}
                            isSaving={isSaving}
                            onSave={handleSave}
                            onDelete={handleDeleteAction}
                        />
                    ) : null
                }
                toolbarPlacement={isMoblie ? 'bottom' : 'top'}
                showKeyline
            >
                {isset(station) ? (
                    <>
                        <Hide tablet desktop>
                            <ExpandingMenu
                                height={46}
                                variant="light"
                                value={currentView}
                                items={[
                                    {
                                        value: ViewType.profile,
                                        label: 'Profile',
                                    },
                                    {
                                        value: ViewType.observations,
                                        label: 'Observations',
                                    },
                                    {
                                        value: ViewType.logs,
                                        label: 'Logs',
                                    },
                                    {
                                        value: ViewType.admin,
                                        label: 'Admin',
                                    },
                                ]}
                                onSelect={(item: any) => {
                                    handleTabSelect(item.value);
                                }}
                            />
                        </Hide>
                        <Hide mobile>
                            <Stack justify="space-between" isInline>
                                <Stack isInline>
                                    <SectionTab
                                        type={ViewType.profile}
                                        currentType={currentView}
                                        onClick={(type: ViewType) => handleTabSelect(type)}
                                    >
                                        Profile
                                    </SectionTab>
                                    <SectionTab
                                        type={ViewType.observations}
                                        currentType={currentView}
                                        onClick={(type: ViewType) => handleTabSelect(type)}
                                    >
                                        Observations
                                    </SectionTab>
                                    <SectionTab
                                        type={ViewType.logs}
                                        currentType={currentView}
                                        onClick={(type: ViewType) => handleTabSelect(type)}
                                    >
                                        Logs
                                    </SectionTab>
                                    {session.user.isAdmin && (
                                        <SectionTab
                                            type={ViewType.admin}
                                            currentType={currentView}
                                            onClick={(type: ViewType) => handleTabSelect(type)}
                                        >
                                            Admin
                                        </SectionTab>
                                    )}
                                </Stack>
                                {session.user.isAdmin && station.user && (
                                    <Stack align="center" isInline>
                                        <Box textAlign="right">
                                            <Text
                                                variant="label"
                                                fontSize="2xs"
                                                color="text.base.tertiary"
                                            >
                                                Owner
                                            </Text>
                                            <Link to={`/user/${station.user.id}`}>
                                                <Text mt={1} variant="caption1" fontWeight="bold">
                                                    {station.user.name}
                                                </Text>
                                            </Link>
                                        </Box>
                                        <Avatar size="sm" name={station.user.name} src="" />
                                    </Stack>
                                )}
                            </Stack>
                        </Hide>
                        <Box mt={[3, null, null, 4]}>
                            <Router basepath={withPrefix(`/station/${station.id}`)} primary={false}>
                                <ProfileView
                                    path="profile"
                                    station={station}
                                    formRef={formRef}
                                    onSubmit={handleFormSubmit}
                                    session={session}
                                    isSaved={isSaved}
                                    default
                                />
                                <ObservationsView
                                    path="observations/*"
                                    station={station}
                                    periodIndex={recordId}
                                />
                                <LogsView path="logs/*" station={station} recordIndex={recordId} />
                                {session.user.isAdmin && (
                                    <AdminView
                                        path="admin"
                                        station={station}
                                        formRef={formRef}
                                        onSubmit={handleFormSubmit}
                                    />
                                )}
                            </Router>
                        </Box>
                    </>
                ) : (
                    <ProfileView station={station} formRef={formRef} onSubmit={handleFormSubmit} session={session} isSaved={isSaved} />
                )}
            </ContentPanelLayout>
            <Dialog
                binding={$confirmDelete}
                title="Delete Station?"
                message="Are you sure you want to delete this station? This cannot be undone!"
                buttons={[
                    <Button
                        key="btn-cancel"
                        variant="outline"
                        variantColor="dark"
                        size="sm"
                        onClick={() => $confirmDelete.setValue(false)}
                    >
                        Cancel
                    </Button>,
                    <Button
                        key="btn-confirm"
                        variant="solid"
                        variantColor="destructive"
                        size="sm"
                        onClick={() => handleDelete()}
                    >
                        Delete Station
                    </Button>,
                ]}
                showActivity={isDeleting}
            />
        </>
    );
};

const StationPage = ({ pageContext, ...rest }: StationPageProps & any) => {
    const location = useLocation();
    const path = parsePath(location.pathname);
    const isEditing = pageContext.action === 'edit';

    const [station, setStation] = useState<Station>();
    const [error, setError] = useState<ApiError>(null);
    const { renderState, setRenderState } = useRenderState();

    const { request: getStation, result, isLoading } = useApi<Station>((api: Api, data: any) => (
        api.routes.stations.getStation(data.id)
    ));

    useEffect(() => {
        if (result.success) {
            setStation(result.object);
        } else {
            setError(result.error);
        }
    }, [result]);

    useEffect(() => {
        if (station) {
            setRenderState(RenderState.Content);
        } else if (isLoading) {
            setRenderState(RenderState.Loading);
        } else if (error) {
            setRenderState(RenderState.Error);
        } else {
            setRenderState(isEditing ? RenderState.Initial : RenderState.Content);
        }
    }, [station, error, isLoading]);

    useEffect(() => {
        if (isEditing && path.id) {
            getStation({ id: path.id });
        }
    }, []);

    return (
        <DashboardLayout
            title={station ? station.displayName : null}
            onGoBack={() => navigate('/stations')}
            {...rest}
        >
            <StatefulRenderer
                state={renderState}
                errorElement={<Text>{error ? error.message : null}</Text>}
            >
                <StationPageContent station={station} />
            </StatefulRenderer>
        </DashboardLayout>
    );
};

const ProtectedStationPage = (props: any) => (
    <ProtectedResource>
        <StationPage {...props} />
    </ProtectedResource>
);

export default ProtectedStationPage;
