import React from 'react';
import { Button, Stack, Text, BoxProps } from 'pws-design-system/design-system';
import SegmentedControl from './SegmentedControl';

interface ToggleRowProps extends BoxProps {
    label: String;
    value?: any;
    options: { value: any; label: string; }[];
    leftElement?: any;
    rightElement?: any;
    onChange?: (value: any) => void;
}

const ToggleRow = ({ label, value, options, leftElement, rightElement, onChange, ...rest }: ToggleRowProps) => (
    <Stack align="center" justify="space-between" isInline {...rest}>
        <Stack isInline>
            {leftElement}
            <Text variant="caption1" fontWeight="bold">{label}</Text>
        </Stack>
        <Stack isInline>
            <SegmentedControl defaultValue={value} onChange={onChange}>
                {(options || []).map((option: any = {}) => (
                    <Button key={`option-${option.value}`} value={option.value}>{option.label}</Button>
                ))}
            </SegmentedControl>
            {rightElement}
        </Stack>
    </Stack>
);

export default ToggleRow;
