import React from 'react';
import { Box, BoxProps, Button, Stack, Text, Badge } from 'pws-design-system/design-system';
import { format } from 'date-fns/esm';
import { navigate } from '@reach/router';
import { isset } from '@aerisweather/javascript-sdk/dist/utils';
import Card, { CardHeading } from '../../../ui/Card';
import MeasureStat from '../../../ui/MeasureStat';
import Observation, { getQCStyle } from '../../../../models/stations/Observation';

interface ObservationDetailViewProps extends BoxProps {
    observation: Observation;
    date: Date;
}

const ObservationDetailView = ({ observation, date, ...rest }: ObservationDetailViewProps) => {
    const qc = getQCStyle(observation.qcCode);

    if (!isset(observation)) {
        return (<>Invalid observation</>);
    }
    return (
        <Box pt={2}>
            <Button
                size="xs"
                variantColor="dark"
                leftIcon="arrow-left"
                onClick={() => navigate(-1)}
            >
                {format(date, 'MMM d, yyyy')}
            </Button>
            <Card mt={3} {...rest}>
                <CardHeading mb={0}>Observation Details</CardHeading>
                <Text variant="label" fontSize="xs" color="text.base.tertiary">
                    {format(new Date(observation.timestamp * 1000), 'h:mm a, M/d/yyyy')}
                </Text>
                <Badge my={3} variant={qc.style} variantColor={qc.color} color={qc.labelColor}>{qc.label}</Badge>
                <Stack mt={3} spacing={4}>
                    <Stack isInline>
                        <MeasureStat
                            width="50%"
                            size="md"
                            label="Temp"
                            icon="temperature"
                            value={observation.tempFormatted}
                        />
                        <MeasureStat
                            width="50%"
                            size="md"
                            label="Feels Like"
                            icon="temperature"
                            value={observation.feelsLikeFormatted}
                        />
                    </Stack>
                    <Stack isInline>
                        <MeasureStat
                            width="50%"
                            size="md"
                            label="Dew Point"
                            icon="dewpoint"
                            value={observation.dewpointFormatted}
                        />
                        <MeasureStat
                            width="50%"
                            size="md"
                            label="Humidity"
                            icon="humidity"
                            value={observation.humidityFormatted}
                        />
                    </Stack>
                    <MeasureStat
                        size="md"
                        label="Winds"
                        icon="wind"
                        value={observation.windSpeedFormatted}
                    />
                    <Stack isInline>
                        <MeasureStat
                            width="50%"
                            size="md"
                            label="Pressure"
                            icon="pressure"
                            value={observation.pressureFormatted}
                        />
                        <MeasureStat
                            width="50%"
                            size="md"
                            label="Precip"
                            icon="precip"
                            value={observation.precipFormatted}
                        />
                    </Stack>
                    <Stack isInline>
                        <MeasureStat
                            width="50%"
                            size="md"
                            label="Solar Rad"
                            icon="solar"
                            value={observation.solarRadiation}
                        />
                        <MeasureStat
                            width="50%"
                            size="md"
                            label="UV Index"
                            icon="uv"
                            value={observation.uvIndex}
                        />
                    </Stack>
                </Stack>
            </Card>
        </Box>
    );
};

export default ObservationDetailView;
