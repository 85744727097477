import React, { useState, useEffect, useRef } from 'react';
import {
    Box, Button, Heading, Form, FormField, Hide, Col, Row, Slider, Stack, Text,
} from 'pws-design-system/design-system';
import { set, get, isPlainObject, isset } from '@aerisweather/javascript-sdk/dist/utils';
import { Helmet } from 'react-helmet';
import Card, { CardHeading } from '../../../ui/Card';
import ToggleRow from '../../../ui/ToggleRow';
import { PassedIcon, FailedIcon } from '../../../ui/Icons';
import { ToolbarProvider } from '../../../../types/ToolbarProvider';
import { Station } from '../../../../models/stations';
import useDataBinding from '../../../hooks/useDataBinding';

const AttributeQualityRow = ({ value, ...rest }: any) => (
    <ToggleRow
        value={value}
        options={[{
            value: 'good',
            label: 'Good',
        }, {
            value: 'bad',
            label: 'Bad',
        }]}
        leftElement={(value) === 'bad' ? <FailedIcon width="20px" height="20px" color="red" /> : <PassedIcon width="20px" height="20px" color="green.400" />}
        {...rest}
    />
);

const qcPasses = (value: boolean): boolean => !isset(value) || value !== false;

const PROP_TITLES: { [key: string]: string } = {
    temp: 'Temperature',
    dewpt: 'Dew Point',
    humidity: 'Humidity',
    winds: 'Wind',
    pressure: 'Pressure',
    precip: 'Precip',
    wind: 'Wind',
    precip_all: 'Precip All',
};

interface AdminViewProps extends ToolbarProvider {
    station: Station;
    formRef: any;
    onSubmit?: (data?: any) => void;
}

const AdminView = ({
    station,
    formRef,
    onSubmit = () => {},
}: AdminViewProps) => {
    const [formData, setFormData] = useState<any>(station.getData());
    const $confidence = useDataBinding(get(formData, 'confidence'));
    const $qcReason = useDataBinding(get(station, 'qcReason'));
    const $placeName = useDataBinding(get(station, 'name'));
    const $stateName = useDataBinding(get(station, 'state'));
    const $pdwElev = useDataBinding(get(formData, 'pdwElev'));
    const sliderRef = useRef(null);

    const handleFieldChange = (value: any) => {
        if (isPlainObject(value)) {
            setTimeout(() => {
                setFormData(value);
            }, 0);
        }
    };

    const updateFormField = (name: string, value: any) => {
        setFormData((prevValue: any) => {
            if (name === 'qcReason') {
                formData.qcReason = value;
                $qcReason.setValue(value);
            }
            if (name === 'placeName') {
                formData.placeName = value;
                $placeName.setValue(value);
            }
            if (name === 'stateName') {
                formData.stateName = value;
                $stateName.setValue(value);
            }
            if (name === 'pdwElev') {
                formData.pdwElev = value;
                $pdwElev.setValue(value);
            }
            const newValue = { ...prevValue, [name]: value };
            set(newValue, name, value);
            // debugger;
            return newValue;
        });
    };

    // useEffect(() => {
    //     setTimeout(() => {
    //         const sliderValue = sliderRef.current.querySelector('input').value;
    //         if (Number(sliderValue) !== formData.confidence) {
    //             updateFormField('confidence', sliderValue);
    //         }
    //     }, 20);

    // }, [formData]);

    const handleReject = () => {
        $confidence.setValue(-1);
    };

    const handleSubmit = () => {
        onSubmit(formData);
    };

    useEffect(() => {
        updateFormField('confidence', $confidence.value);
    }, [$confidence.value]);

    useEffect(() => {
        updateFormField('placeName', $placeName.value);
    }, [$placeName.value]);

    useEffect(() => {
        updateFormField('stateName', $stateName.value);
    }, [$stateName.value]);

    useEffect(() => {
        updateFormField('pdwElev', $pdwElev.value);
    }, [$pdwElev.value]);

    return (
        <>
            <Helmet>
                <title>{station.displayName} - Admin | PWSWeather</title>
            </Helmet>
            <Form
                method="post"
                data={{ ...formData }}
                ref={formRef}
                onSubmit={handleSubmit}
                onChange={handleFieldChange}
            >
                <Row>
                    <Col xl={6} xxl={4}>
                        <Card>
                            <CardHeading>Profile</CardHeading>
                            <Stack spacing={4}>
                                <FormField
                                    name="stationId"
                                    label="Station Identifier"
                                    help="The unique identifier for the station. Must be 5-10 characters."
                                    validate={(value: string = '') => {
                                        if (value.length < 5 || value.length > 50) {
                                            return 'Identifier must be between 5 and 10 characters in length.';
                                        }
                                        return false;
                                    }}
                                    field={{
                                        variant: 'flushed',
                                    }}
                                />
                            </Stack>
                            <Stack spacing={4}>
                                <FormField
                                    name="placeName"
                                    label="Place Name"
                                    help="The city or location name saved in aeris api via pdw"
                                    validate={(value: string = '') => {
                                        if (value.length < 2 || value.length > 50) {
                                            return 'Identifier must be between 5 and 10 characters in length.';
                                        }
                                        return false;
                                    }}
                                    field={{
                                        variant: 'flushed',
                                    }}
                                    // onChange={(value) => $placeName.setValue(value)}
                                />
                            </Stack>
                            <Stack spacing={4}>
                                <FormField
                                    name="stateName"
                                    label="State"
                                    help="The state name saved in aeris api via pdw"
                                    field={{
                                        variant: 'flushed',
                                    }}
                                    // onChange={(value) => $placeName.setValue(value)}
                                />
                            </Stack>
                            <Stack spacing={4}>
                                <FormField
                                    name="country"
                                    label="Country"
                                    help="The country name saved in aeris api via pdw"
                                    field={{
                                        variant: 'flushed',
                                    }}
                                    // onChange={(value) => $placeName.setValue(value)}
                                />
                            </Stack>
                            <Stack spacing={4}>
                                <FormField
                                    name="pdwElev"
                                    label="Elevation"
                                    help="The elevation saved in aeris api via pdw"
                                    field={{
                                        variant: 'flushed',
                                    }}
                                    // onChange={(value) => $placeName.setValue(value)}
                                />
                            </Stack>
                        </Card>
                    </Col>
                    <Col xl={6} xxl={8}>
                        <Card>
                            <CardHeading>Quality Control</CardHeading>
                            {formData.status ? (
                                <Stack flexGrow={1} flexDirection={['column', null, null, null, 'row']}>
                                    <Stack spacing={4} width={['100%', null, null, null, '50%']}>
                                        <Box>
                                            <Heading as="h3" mb={2} fontSize="xl">
                                                Confidence Level
                                            </Heading>
                                            <Text variant="caption1" color="text.base.secondary">
                                                Set the overall station confidence level, where{' '}
                                                <strong>0</strong> is the lowest confidence and{' '}
                                                <strong>10</strong> is the highest. Station confidence
                                                is factored into the station&apos;s total trust factor.
                                            </Text>
                                            <Stack mt={4} mx={3} align="center" isInline>
                                                <Slider.Container
                                                    min={-1}
                                                    max={10}
                                                    step={1}
                                                    value={$confidence.value || 0}
                                                    ref={sliderRef}
                                                    onChange={(value) => $confidence.setValue(value)}
                                                >
                                                    <Slider.Track />
                                                    <Slider.FilledTrack />
                                                    <Slider.Thumb />
                                                </Slider.Container>
                                                <Text
                                                    width="80px"
                                                    height="35px"
                                                    variant="headline"
                                                    textAlign="right"
                                                >
                                                    {$confidence.value || 0}
                                                </Text>
                                            </Stack>
                                        </Box>
                                        <Stack spacing={3} isInline>
                                            <Button
                                                size="xs"
                                                variantColor="dark"
                                                onClick={handleReject}
                                            >
                                                Reject Station
                                            </Button>
                                            <Text
                                                width={1 / 2}
                                                variant="caption2"
                                                color="text.base.tertiary"
                                            >
                                                Reject a station if it is consistently sending bad
                                                and/or questionable data or if it appears to be indoors.
                                                This will set its confidence level to -1 and prevent it
                                                from automatically increasing.
                                            </Text>
                                        </Stack>
                                        <FormField
                                            type="textarea"
                                            name="qcReason"
                                            label="Reason"
                                            help="Provide a brief message about why the confidence level and/or data attribute quality were changed."
                                            field={{
                                                variant: 'flushed',
                                                onChange: ((event) => updateFormField(
                                                    'qcReason',
                                                    event.target.value,
                                                )),
                                            }}
                                        />
                                    </Stack>
                                    <Hide mobile>
                                        <Box width="64px" />
                                    </Hide>
                                    <Box
                                        width={['100%', null, null, null, '50%']}
                                        mt={[4, null, null, 0]}
                                    >
                                        <Heading as="h3" mb={2} fontSize="xl">
                                            Data Attributes
                                        </Heading>
                                        <Text variant="caption1" color="text.base.secondary">
                                            Set individual properties as failing which can be used
                                            instead of lowering the entire station&apos;s confidence
                                            level.
                                        </Text>
                                        <Stack spacing={2} mt={4}>
                                            <Box height="2px" bg="bg.100" />
                                            {(Object.keys(formData.qc) || [])
                                                .filter((prop) => prop !== 'confidence')
                                                .map((prop: string) => (
                                                    <Stack key={`prop-${prop}`} spacing={2}>
                                                        <AttributeQualityRow
                                                            label={PROP_TITLES[prop] || prop}
                                                            value={
                                                                qcPasses(formData.qc[prop])
                                                                    ? 'bad'
                                                                    : 'good'
                                                            }
                                                            onChange={(value: any) => {
                                                                updateFormField(
                                                                    `qc.${prop}`,
                                                                    value === 'bad',
                                                                );
                                                            }}
                                                        />
                                                        <Box height="2px" bg="bg.100" />
                                                    </Stack>
                                                ))}
                                        </Stack>
                                    </Box>
                                </Stack>
                            ) : (
                                <Text variant="caption1" color="text.base.secondary">
                                    This station has not been activated yet.
                                </Text>
                            )}
                        </Card>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default AdminView;
