import React, { useRef, useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { Hide, Text, Heading } from 'pws-design-system/design-system';
import useResizeAware from 'pws-design-system/design-system/hooks/useResizeAware';
import { formatDistance } from 'date-fns';
import { isset, get } from '@aerisweather/javascript-sdk/dist/utils';
import { navigate } from 'gatsby';
import DataTable from '../../../ui/DataTable';
import LogsListView from './LogsListView';
import ActivityIndicator from '../../../ui/ActivityIndicator';
import LogDetailView from './LogDetailView';
import useApi, { Api } from '../../../hooks/api/useApi';
import { Station } from '../../../../models/stations';
import StationLogs from '../../../../models/stations/StationLogs';
import { Helmet } from 'react-helmet';

interface LogsViewProps {
    station: Station;
    recordIndex?: number;
}

const LogsView = ({ station, recordIndex, ...rest }: LogsViewProps) => {
    const [logs, setLogs] = useState<StationLogs>(new StationLogs());
    const [error, setError] = useState(null);

    const containerRef = useRef();
    const size = useResizeAware(containerRef);

    const { request: getLogs, result, isLoading } = useApi<StationLogs>((api: Api, data: any) => (
        api.routes.stations.getLogs(data.id)
    ));

    const handleSelectRow = (index: number) => {
        navigate(`/station/${station.id}/logs/${index}`);
    };

    useEffect(() => {
        getLogs({ id: station.id });
    }, []);

    useEffect(() => {
        if (result.success) {
            setError(null);
            setLogs(result.object.sortDateDescending());
        } else {
            const errorMessage = get(result, 'error.message');
            if (errorMessage) {
                setError(errorMessage);
            } else {
                setError('An unknown error occured.');
            }
        }
    }, [result]);

    if (isLoading || !isset(logs)) {
        return (
            <ActivityIndicator />
        );
    }

    if (error) {
        return (
            <>
                <Helmet>
                    <title>{station.displayName} - Logs | PWSWeather</title>
                </Helmet>
                <Heading as="h2" fontSize="3xl" mb={3} mt={[2, null, null, 0]}>
                    {error}
                </Heading>
            </>
        );
    }

    return isset(recordIndex) ? (
        <>
            <Helmet>
                <title>{station.displayName} - Logs | PWSWeather</title>
            </Helmet>
            <LogDetailView log={logs.at(recordIndex)} />
        </>
    ) : (
        <>
            <Helmet>
                <title>{station.displayName} - Logs | PWSWeather</title>
            </Helmet>
            <div ref={containerRef}>
                <Hide tablet desktop>
                    <LogsListView
                        logs={logs}
                        listSize={{ width: size.width || 300, height: 500 }}
                        onSelectCell={handleSelectRow}
                    />
                </Hide>
                <Hide mobile>

                    <DataTable
                        cssOverrides={css({
                            '.table': {
                                '.td': {
                                    padding: '20px 12px',
                                },
                            },
                        })}
                        columns={[
                            {
                                title: 'Date/Time',
                                field: 'date',
                                align: 'left',
                                width: 150,
                                formatter: (value: any) => {
                                    if (isset(value)) {
                                        return `${formatDistance(new Date(), new Date(value))} ago`;
                                    }
                                    return 'N/A';
                                },
                            },
                            {
                                title: 'Action',
                                field: 'action',
                                align: 'left',
                            },
                            {
                                title: 'Updated By',
                                field: 'updatedBy',
                            },
                            {
                                title: 'Confidence',
                                field: 'confidence',
                            },
                            {
                                title: 'Notes',
                                field: 'message',
                                align: 'left',
                            },
                        ]}
                        data={logs.toArray()}
                        virtualize={false}
                    />
                </Hide>
            </div>
        </>
    );
};

export default LogsView;
