import React, { useState, useEffect } from 'react';
import { Box, Button, FormField, Stack, Text, BoxProps } from 'pws-design-system/design-system';
import { ICoordinate } from '@aerisweather/javascript-sdk/dist/interfaces/ICoordinate';
import { isset } from '@aerisweather/javascript-sdk/dist/utils';
import Map, { MapViewport } from '../../../ui/Map';
import PositionMarker from '../../../ui/PositionMarker';
import useApi, { Api } from '../../../hooks/api/useApi';
import { store as unitStore } from '../../../hooks/store/useUnitStore';
import Place from '../../../../models/Place';
import { MeasureType } from '../../../../types/enums';
import { fullUnitsForMeasureType, isMetricValue } from '../../../../utils/units';

const formatCoord = (coord: ICoordinate, precision: number = 8): string => {
    if (!coord) return '';
    return `${parseFloat(`${coord.lat}`).toFixed(precision)}, ${parseFloat(`${coord.lon}`).toFixed(precision)}`;
};

const isValidCoord = (coord: ICoordinate): boolean => isset(coord.lat) && isset(coord.lon);

interface CoordinateEditorProps extends BoxProps {
    coordinate?: { lat: number, lon: number };
    allowEditingCoordinates?: boolean;
    onMapBoundsChange?: (info: any) => void;
    onElevationLookup?: (value: number) => void;
}

const CoordinateEditor = ({
    coordinate = { lat: null, lon: null },
    allowEditingCoordinates = true,
    onMapBoundsChange = () => {},
    onElevationLookup = () => {},
    ...rest
}: CoordinateEditorProps) => {
    const hasCoord = (): boolean => isValidCoord(coordinate);
    const [mapCenter, setMapCenter] = useState<ICoordinate>(coordinate);
    const [mapZoom, setMapZoom] = useState(hasCoord() ? 15 : 3);
    const [elevationError, setElevationError] = useState<string>();

    const { request: lookupElevation, result, isLoading } = useApi<Place>((api: Api, data: any) => (
        api.routes.location.elevation(data.lat, data.lon)
    ));

    const handleMapBoundsChange = (info: any = {}) => {
        onMapBoundsChange(info);
    };

    const handleElevationLookup = () => {
        if (hasCoord()) {
            const { lat, lon } = coordinate;
            setElevationError(null);
            lookupElevation({ lat, lon });
        } else {
            setElevationError('A valid latitude/longitude coordinate is required above to lookup your elevation.');
        }
    };

    const handleElevationBlur = (event) => {
        const { value } = event.target as HTMLInputElement;
        onElevationLookup(value);
    };

    useEffect(() => {
        if (result.success && result.object) {
            onElevationLookup(
                Math.round(
                    isMetricValue(unitStore.get(MeasureType.Height))
                        ? result.object.elevationM
                        : result.object.elevationFT,
                ),
            );
        }
    }, [result]);

    useEffect(() => {
        if (isset(coordinate.lat) && isset(coordinate.lon) && (coordinate.lat !== mapCenter.lat || coordinate.lon !== mapCenter.lon)) {
            setMapCenter(coordinate);
        }
        setMapZoom(15);
    }, [coordinate]);

    return (
        <Stack {...rest}>
            <Box position="relative" height="300px">
                <Map
                    bg="bg.500"
                    height="full"
                    aerisId="wgE96YE3scTQLKjnqiMsv"
                    aerisSecret="6E9YI5H9qCO9m44qoEUccvL7UZVSrGVG3gbO7rGW"
                    defaultCenter={mapCenter}
                    center={mapCenter}
                    defaultZoom={mapZoom}
                    onViewportChange={(viewport: MapViewport) => handleMapBoundsChange(viewport)}
                />
                <PositionMarker zIndex={1000} />
            </Box>
            <Text variant="caption2" color="text.base.tertiary">
                Pan and/or zoom to re-center the map. Your station coordinates will be updated using
                the current center coordinate of the map above.
            </Text>
            <Stack mt={3} spacing={4} isInline>
                <FormField
                    type="input"
                    name="location.latitude"
                    label="Latitude"
                    field={{
                        type: 'number',
                        step: 'any',
                        variant: 'flushed',
                    }}
                    minWidth="100px"
                    disabled={allowEditingCoordinates === false}
                    required
                />
                <FormField
                    type="input"
                    name="location.longitude"
                    label="Longitude"
                    field={{
                        type: 'number',
                        step: 'any',
                        variant: 'flushed',
                    }}
                    minWidth="100px"
                    disabled={allowEditingCoordinates === false}
                    required
                />
            </Stack>
            <Box mt={3}>
                <FormField
                    type="radio"
                    name="location.precision"
                    label="Precision"
                    defaultValue="6"
                    help={`Adjust the precision of the station’s coordinate when displayed publicly
                        on a map or within API results. Less exact precision will result in your
                        station appearing at a more generalized location at a city block or
                        neighborhood level.`}
                    field={{
                        options: [
                            {
                                value: '6',
                                label: 'Precise',
                            },
                            {
                                value: '3',
                                label: (
                                    <>
                                        Block{' '}
                                        {hasCoord() && (
                                            <Text
                                                as="span"
                                                variant="caption1"
                                                color="text.base.tertiary"
                                            >
                                                ({formatCoord(coordinate, 3)})
                                            </Text>
                                        )}
                                    </>
                                ),
                            },
                            {
                                value: '2',
                                label: (
                                    <>
                                        Neighborhood{' '}
                                        {hasCoord() && (
                                            <Text
                                                as="span"
                                                variant="caption1"
                                                color="text.base.tertiary"
                                            >
                                                ({formatCoord(coordinate, 2)})
                                            </Text>
                                        )}
                                    </>
                                ),
                            },
                        ],
                    }}
                />
            </Box>
            <Box>
                <Stack align="flex-end" mt={3} spacing={3} isInline>
                    <FormField
                        type="input"
                        name="location.elevation"
                        label="Elevation"
                        error={elevationError}
                        onBlur={handleElevationBlur}
                        field={{
                            type: 'number',
                            step: 'any',
                            min: '0',
                            variant: 'flushed',
                        }}
                        width={['80px', null, null, '120px']}
                        required
                    />
                    <Text variant="body">
                        {fullUnitsForMeasureType(
                            MeasureType.Height,
                            isMetricValue(unitStore.get(MeasureType.Height)),
                        )}
                    </Text>
                    <Button
                        mt="30px"
                        size="xs"
                        variantColor="dark"
                        onClick={handleElevationLookup}
                        isLoading={isLoading}
                    >
                        Lookup
                    </Button>
                </Stack>
                <Text mt={2} variant="caption2" color="text.base.tertiary">
                    Provide the station elevation or perform a lookup to automatically determine the
                    elevation based on the current coordinates above. A valid latitude/longitude
                    coordinate is required above to determine the elevation via the lookup option.
                </Text>
            </Box>
        </Stack>
    );
};

export default CoordinateEditor;
