import React from 'react';
import { Svg } from 'pws-design-system/design-system';

export const PassedIcon = (props: any) => (
    <Svg viewBox="0 0 100 100" fill="currentColor" xmlns="http://www.w3.org/2000/svg" version="1.1" {...props}>
        <path d="m76.875 22.75c-0.089844 0.085938-3.1289 3.0039-7 6.6875s-8.875 8.4375-13.875 13.188-9.9961 9.4961-13.875 13.188c-1.3672 1.3008-1.5547 1.4766-2.5625 2.4375l-17.188-14.844-9.125 10.594 22 19 4.9062 4.25 4.5938-4.5938c0.089844-0.089844 3.1289-3.0039 7-6.6875s8.875-8.4375 13.875-13.188 9.9961-9.4961 13.875-13.188 6.3359-6.0234 7.25-6.9375z" />
    </Svg>
);

export const FailedIcon = (props: any) => (
    <Svg viewBox="0 0 100 100" fill="currentColor" xmlns="http://www.w3.org/2000/svg" version="1.1" {...props}>
        <g>
            <path d="m50 0c-27.344-0.019531-49.516 22.375-49.539 49.973 0.023437 27.645 22.195 50.039 49.539 50.027 27.352 0.011719 49.52-22.383 49.543-50.027-0.023438-27.598-22.191-49.992-49.543-49.973zm0 88.477c-21.035 0-38.094-17.227-38.078-38.504-0.019531-21.23 17.039-38.461 38.078-38.453 21.047-0.011719 38.105 17.223 38.133 38.453-0.027343 21.281-17.086 38.504-38.133 38.504z" />
            <path d="m72.027 37.465l-9.4961-9.4961-12.531 12.535-12.535-12.535-9.4961 9.4961 12.539 12.543-12.539 12.527 9.4961 9.4961 12.535-12.535 12.531 12.535 9.4961-9.4961-12.531-12.527z" />
        </g>
    </Svg>
);
