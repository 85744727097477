import { get, isset } from '@aerisweather/javascript-sdk/dist/utils';
// import { Badge, Box, Button, Col, Form, FormField, Icon, IconButton, Row, Stack, Text } from 'pws-design-system/design-system';
import { Box, Button, Col, Form, FormField, Icon, IconButton, Row, Stack, Text } from 'pws-design-system/design-system';
import { FormContext } from 'pws-design-system/design-system/components/form/Form';
import useCopyToClipboard from 'pws-design-system/design-system/hooks/useCopyToClipboard';
import React, { useEffect, useRef, useState } from 'react';
import { Station } from '../../../../models/stations';
import { MeasureType, StatusType } from '../../../../types/enums';
import { isMetricValue } from '../../../../utils/units';
import useApi, { Api } from '../../../hooks/api/useApi';
import { store as unitStore } from '../../../hooks/store/useUnitStore';
import useBeforeUnload from '../../../hooks/useBeforeUnload';
import useDataBinding from '../../../hooks/useDataBinding';
import Card, { CardHeading } from '../../../ui/Card';
import SegmentedControl from '../../../ui/SegmentedControl';
import Toast, { useToast } from '../../../ui/Toast';
import ToggleRow from '../../../ui/ToggleRow';
import { AppState } from '../context/auth';
import AddressEditor from './AddressEditor';
import CoordinateEditor from './CoordinateEditor';
import LinkStationDialog from './LinkStationDialog';

enum LocationType {
    Address = 'address',
    Coordinate = 'coordinate'
}

interface ProfileViewProps {
    station: Station;
    formRef: any;
    onSubmit?: (data?: any) => void;
    session: AppState,
}

const ProfileView = ({
    station,
    formRef,
    session,
    onSubmit = () => {},
    isSaved,
}: ProfileViewProps) => {
    const stationData: any = station ? station.toObject() : {
        location: {},
        integration: null,
    };
    const [locationType, setLocationType] = useState(LocationType.Address);
    const [showPrivate, setShowPrivate] = useState(false);

    const $coord = useDataBinding({ lat: get(stationData, 'location.latitude'), lon: get(stationData, 'location.longitude') });
    const $elevation = useDataBinding(
        Math.round(get(
            stationData,
            isMetricValue(unitStore.get(MeasureType.Height))
                ? 'location.elevationM'
                : 'location.elevationFT',
        )),
    );
    const $confidence = useDataBinding(get(stationData, 'confidence'));
    const $qcReason = useDataBinding(get(stationData, 'qcReason'));
    const $status = useDataBinding(get(stationData, 'status'));
    const $pressureType = useDataBinding(get(stationData, 'pressureType') || 'mslp');
    const $precision = useDataBinding(get(stationData, 'location.precision') || 6);
    const $linkEnabled = useDataBinding(get(stationData, 'integration.enabled') || false);

    const $davisLinkBinding = useDataBinding(null);
    const [davisFormData, setDavisFormData] = useState(stationData.integration || null);
    const [davisFormDataModified, setDavisFormDataModified] = useState(false);

    useEffect(() => {
        if (isSaved === true) {
            setDavisFormDataModified(false);
        }
    }, [isSaved]);

    const handleSubmit = async (value:any) => {
        const newVal = { ...value };
        if (locationType === LocationType.Coordinate) {
            delete newVal.location.country;
            delete newVal.location.state;
        }
        onSubmit(newVal);
    };

    const { request: resetKey, result: resetKeyResult, isLoading: isResettingKey } = useApi<Station>((api: Api) => (
        api.routes.stations.resetKey(station.id)
    ));

    const setModified = useBeforeUnload("You've made changes to the form. Are you sure you want to continue before saving?");

    const clipboard = useCopyToClipboard();
    const toast = useToast();
    const localFormRef = useRef<any>();
    const isEditing = isset(station);

    const handleFieldChange = () => {
        setModified(true);
    };

    const handleGeocode = (coord: { lat: number, lon: number }) => {
        $coord.setValue(coord);
    };

    const handleMapBoundsChange = (info: any) => {
        const { center } = info;
        $coord.setValue(center);
    };

    const handleElevationLookup = (value: number) => {
        $elevation.setValue(Number(value));
    };

    const handleResetKey = () => {
        resetKey();
    };

    const handleCopyKey = () => {
        clipboard.copy(station.apiKey);
    };

    const handleStatusChange = (value: 'active' | 'rejected') => {
        if (value === 'rejected') {
            $confidence.setValue(-1);
            $qcReason.setValue('Rejected on station profile.');
            return;
        }
        $confidence.setValue(10);
        $qcReason.setValue('Activated on station profile.');
    };

    useEffect(() => {
        const form = localFormRef.current;
        form.update('location.latitude', $coord.value.lat);
        form.update('location.longitude', $coord.value.lon);
    }, [$coord.value]);

    useEffect(() => {
        const form = localFormRef.current;
        form.update('location.elevation', $elevation.value);
    }, [$elevation.value]);

    useEffect(() => {
        const form = localFormRef.current;
        form.update('pressureType', $pressureType.value);
    }, [$pressureType.value]);

    useEffect(() => {
        const form = localFormRef.current;
        form.update('location.precision', $precision.value);
    }, [$precision.value]);

    useEffect(() => {
        const form = localFormRef.current;
        form.update('confidence', $confidence.value);
        form.update('qcReason', $qcReason.value);
    }, [$confidence.value]);

    useEffect(() => {
        if (formRef) {
            formRef.current = localFormRef.current; // eslint-disable-line no-param-reassign
        }
    }, [localFormRef.current]);

    useEffect(() => {
        console.log($status);
    }, [$status.value]);

    useEffect(() => {
        if (resetKeyResult.success === true) {
            const form = localFormRef.current;
            form.update('apiKey', resetKeyResult.data.station.appKey);
        }
    }, [resetKeyResult]);

    useEffect(() => {
        if (clipboard.copied) {
            toast({
                duration: 5000,
                isClosable: true,
                render: () => (
                    <Toast variant="success" title="API Key Copied" message="The station's API key was copied to the clipboard." icon="check-circle" />
                ),
            });
        }
    }, [clipboard.copied]);

    const $linkStationBinding = useDataBinding(false);
    const onLinkStationClick = () => {
        $linkStationBinding.setValue(true);
        $davisLinkBinding.setValue(null);
    };

    useEffect(() => {
        if (!$davisLinkBinding.value) { return; }
        const linkedStation = $davisLinkBinding.value.station;
        const { publicKey } = $davisLinkBinding.value;
        const { privateKey } = $davisLinkBinding.value;

        if (linkedStation) {
            setDavisFormData({
                enabled: true,
                service: 'davis-v2',
                davis: {
                    id: linkedStation.stationId,
                    publicKey,
                    privateKey,
                },
            });
            $davisLinkBinding.setValue(null);
            setDavisFormDataModified(true);
            const form = localFormRef.current;
            setLocationType(LocationType.Coordinate);
            form.update('location.city', linkedStation.state);
            form.update('location.latitude', linkedStation.latitude);
            form.update('location.longitude', linkedStation.longitude);
            form.update('location.elevation', linkedStation.elevation);
            form.update('location.elevationFT', linkedStation.elevation);
            form.update('integration.enabled', true);
            form.update('integration.service', 'davis-v2');
            form.update('integration.davis.publicKey', publicKey);
            form.update('integration.davis.privateKey', privateKey);
            form.update('integration.davis.id', linkedStation.stationId.toString());
            // form.update('integration.davis.name', linkedStation.name);
            $linkEnabled.setValue(true);
        }
    }, [$davisLinkBinding.value]);

    const onDeleteDavisLinkClick = () => {
        const form = localFormRef.current;
        form.update('integration', {});
        setDavisFormData(null);
    };

    const onDisableStationLink = () => {
        const form = localFormRef.current;
        form.update('integration.enabled', false);
        setDavisFormDataModified(true);
        setDavisFormData({
            ...davisFormData,
            enabled: false,
        });
    };

    const onEnableStationLink = () => {
        const form = localFormRef.current;
        form.update('integration.enabled', true);
        setDavisFormDataModified(true);
        setDavisFormData({
            ...davisFormData,
            enabled: true,
        });
    };

    return (
        <>
            <Form
                method="get"
                data={stationData}
                ref={(ref) => {
                    localFormRef.current = ref;
                }}
                onSubmit={(e: any) => handleSubmit(e.value)}
                onChange={handleFieldChange}
            >
                <Row>
                    <Col xl={4}>
                        <Card>
                            <CardHeading>Profile</CardHeading>
                            <Stack spacing={4}>
                                {session && session.isAdmin && $status.value !== StatusType.Inactive && (
                                    <Box>
                                        <ToggleRow
                                            label="Status"
                                            value={
                                                station && $status.value === StatusType.Rejected
                                                    ? 'rejected'
                                                    : 'active'
                                            }
                                            options={[
                                                {
                                                    value: 'active',
                                                    label: 'Active',
                                                },
                                                {
                                                    value: 'rejected',
                                                    label: 'Rejected',
                                                },
                                            ]}
                                            onChange={(value: any) => handleStatusChange(value)}
                                        />
                                        {$status.value !== StatusType.Inactive && (
                                            <Text mt={2} variant="caption2" color="text.base.tertiary">
                                                Setting a station to rejected prevents it from appearing
                                                in AerisWeather API requests and the PWSWeather website.
                                                Use this if you want to disable a station without
                                                deleting it.
                                            </Text>
                                        )}
                                    </Box>
                                )}
                                <FormField
                                    type="input"
                                    name="stationId"
                                    label="Station ID"
                                    help="Unique identifier for the station. Can consist of only letters or numbers with no spaces."
                                    field={{
                                        variant: 'flushed',
                                        disabled: isset(station),
                                    }}
                                    required
                                />
                                {session && session.isAdmin && (
                                    <FormField
                                        type="input"
                                        name="userId"
                                        label="User ID"
                                        help="ID of the user to assign the station to."
                                        field={{
                                            variant: 'flushed',
                                        }}
                                        required
                                    />
                                )}
                                {davisFormData === null && (
                                    <Stack isInline alignItems="flex-end" justifyContent="space-between">
                                        <>
                                            <Box flexGrow={1}>
                                                <FormField
                                                    type="input"
                                                    name="hardware"
                                                    label="Hardware"
                                                    field={{
                                                        variant: 'flushed',
                                                    }}
                                                />
                                            </Box>
                                            <Button
                                                size="xs"
                                                variantColor="dark"
                                                onClick={onLinkStationClick}
                                                disabled={$linkStationBinding.value}
                                            >
                                                Link Station
                                            </Button>
                                        </>
                                    </Stack>
                                )}
                                {davisFormData !== null && davisFormData.service === 'davis-v2' && (
                                    <Box bg="gray.800" px={3} py={3} mx={-3} my={-3} borderRadius={1}>
                                        <Stack spacing={3}>
                                            <Box>
                                                {davisFormData.enabled === true && (
                                                    <Stack isInline spacing={2}>
                                                        <Icon name={davisFormDataModified ? 'warning' : 'check-circle'} size="18px" color={davisFormDataModified ? 'yellow.300' : 'green.300'} />
                                                        {isEditing ? (
                                                            <Box>{davisFormDataModified ? <>This station will be linked to Davis Instruments after you have saved.</> : (<>This station is linked to Davis Instruments and will automatically update on PWSWeather.</>)}</Box>
                                                        ) : (
                                                            <Box>This station will be linked to Davis Instruments after you have saved.</Box>
                                                        )}
                                                    </Stack>
                                                )}
                                                {davisFormData.enabled === false && (
                                                    <Stack isInline spacing={2}>
                                                        <Icon name="warning-2" size="18px" color="yellow.300" />
                                                        <Box>{davisFormDataModified ? <>This station&pos;s link to Davis Instruments will be disabled after you have saved.</> : (<>This station&apos;s link to Davis Instruments is disabled.</>)}</Box>
                                                    </Stack>
                                                )}
                                            </Box>
                                            <Box>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row" align="left">
                                                                <Box py={1} paddingRight={2}>Id</Box>
                                                            </th>
                                                            <td>
                                                                <Text fontFamily="monospace">
                                                                    {davisFormData.davis.id}
                                                                </Text>
                                                            </td>
                                                        </tr>
                                                        {/* <tr>
                                                            <th scope="row" align="left">
                                                                <Box py={1} paddingRight={2}>Name</Box>
                                                            </th>
                                                            <td>{$davisLinkBinding.value.station.name}</td>
                                                        </tr> */}
                                                        <tr>
                                                            <th scope="row" align="left">
                                                                <Box py={1} paddingRight={2}>Public Key</Box>
                                                            </th>
                                                            <td><Text fontFamily="monospace">{davisFormData.davis.publicKey}</Text></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" align="left">
                                                                <Box py={1} paddingRight={2}>Private Key</Box>
                                                            </th>
                                                            <td>
                                                                <Stack isInline>
                                                                    <Box>
                                                                        <Text fontFamily="monospace">
                                                                            {showPrivate ? davisFormData.davis.privateKey : new Array(davisFormData.davis.privateKey.length).fill('•').join('')}
                                                                        </Text>
                                                                    </Box>
                                                                    <Icon name={showPrivate ? 'view-off' : 'view'} size="18px" onClick={() => setShowPrivate(!showPrivate)} />
                                                                </Stack>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Box>
                                            <Stack isInline>
                                                {isEditing && (
                                                    <Box>
                                                        <Button
                                                            size="xs"
                                                            variantColor="light"
                                                            onClick={onLinkStationClick}
                                                        >
                                                            Edit
                                                        </Button>
                                                    </Box>
                                                )}

                                                {davisFormData.enabled === false && isEditing && !davisFormDataModified && (
                                                    <Box>
                                                        <Button
                                                            size="xs"
                                                            variantColor="brand"
                                                            onClick={onEnableStationLink}
                                                        >
                                                            Enable
                                                        </Button>
                                                    </Box>
                                                )}

                                                {davisFormData.enabled === true && isEditing && (
                                                    <Box>
                                                        <Button
                                                            size="xs"
                                                            variant="outline"
                                                            variantColor="light"
                                                            disabled={!isSaved}
                                                            onClick={onDisableStationLink}
                                                        >
                                                            Disable
                                                        </Button>
                                                    </Box>
                                                )}
                                                <Box>
                                                    <Button
                                                        size="xs"
                                                        variantColor="destructive"
                                                        onClick={onDeleteDavisLinkClick}
                                                    >
                                                        Remove
                                                    </Button>
                                                </Box>
                                                {/* <Box>
                                                    <FormField
                                                        type="checkbox"
                                                        name="integration.enabled"
                                                        label="Disable Link"
                                                        onChange={onDisabledIntegrationChange}
                                                        value={$linkEnabled.value}
                                                        field={{
                                                            checked: $linkEnabled.value === false
                                                        }}
                                                    />
                                                </Box> */}
                                            </Stack>
                                        </Stack>
                                    </Box>
                                )}
                                {davisFormData === null && (
                                    <Box>
                                        <FormField
                                            type="input"
                                            name="software"
                                            label="Software"
                                            field={{
                                                variant: 'flushed',
                                            }}
                                        />
                                    </Box>
                                )}
                                <Stack spacing={1}>
                                    {/* <Box>
                                        <Badge variant="outline" variantColor="yellow">
                                            Coming Soon
                                        </Badge>
                                    </Box>
                                    <FormField
                                        type="radio"
                                        name="pressureType"
                                        label="Pressure Type"
                                        defaultValue="altimeter"
                                        help="Set the method which your station or station software reports barometric pressure."
                                        field={{
                                            variant: 'flushed',
                                            marginBottom: 0,
                                            options: [
                                                {
                                                    value: 'mslp',
                                                    label: 'MSLP (mean sea-level pressure)',
                                                },
                                                {
                                                    value: 'station',
                                                    label: 'Station/Surface Pressure',
                                                },
                                                {
                                                    value: 'altimeter',
                                                    label: 'Altimeter',
                                                },
                                            ],
                                        }}
                                    />
                                    <Text fontSize="xs" color="yellow.500">
                                        While you can set the pressure type for your station, we are currently working to better support this feature on the data and output side.
                                    </Text> */}
                                    {isEditing && (
                                        <Box>
                                            <Stack mt={1} spacing={0} align="center" isInline>
                                                <Box>
                                                    <Text mb={2} variant="caption1" fontWeight="bold">
                                                        API Key
                                                    </Text>
                                                </Box>
                                                <FormField
                                                    type="input"
                                                    name="apiKey"
                                                    field={{
                                                        variant: 'unstyled',
                                                        disabled: true,
                                                    }}
                                                    width={['80%', null, null, null]}
                                                />
                                                <IconButton
                                                    icon="copy"
                                                    variant="ghost"
                                                    onClick={handleCopyKey}
                                                    aria-label="Copy API Key"
                                                />
                                                <Button
                                                    size="xs"
                                                    variantColor="dark"
                                                    onClick={handleResetKey}
                                                    isLoading={isResettingKey}
                                                >
                                                    {isset(station) ? 'Regenerate' : 'Generate'}
                                                </Button>
                                            </Stack>
                                            <Text mt={2} variant="caption2" color="text.base.tertiary">
                                                Use this unique key when posting data to the PWSWeather API
                                                from your weather station software. You can generate a new
                                                key, but you must also update the key used when posting data
                                                to the API.
                                            </Text>
                                        </Box>
                                    )}
                                </Stack>
                            </Stack>
                        </Card>
                    </Col>
                    <Col xl={8}>
                        <Card width={locationType === LocationType.Coordinate ? '50%' : '100%'}>
                            <Stack mb={3} spacing={4} align="center" isInline>
                                <CardHeading mb={0}>Location</CardHeading>
                                <SegmentedControl
                                    mt={-2}
                                    value={locationType}
                                    onChange={(value: LocationType) => setLocationType(value)}
                                >
                                    <Button value={LocationType.Address}>Address</Button>
                                    <Button value={LocationType.Coordinate}>Coordinate</Button>
                                </SegmentedControl>
                            </Stack>
                            <Row>
                                <FormContext.Consumer>
                                    {({ value: formData }: any) => (
                                        <>
                                            {locationType === LocationType.Address && (
                                                <>
                                                    <Col md={12} xxl={6}>
                                                        <Box mb={4}>
                                                            <Text
                                                                variant="caption1"
                                                                color="text.base.tertiary"
                                                            >
                                                                Provide the physical address for your station
                                                                below. The latitude/longitude coordinate can be
                                                                automatically determined based on the address
                                                                you provide using the &quot;Geocode
                                                                Address&quot; control.
                                                            </Text>
                                                            {isset(station)
                                                            && (
                                                                <Text
                                                                    variant="caption1"
                                                                    color="text.base.tertiary"
                                                                    mt={2}
                                                                >
                                                                    Changing your location will reset your station status. This means it may
                                                                    take up to five days before it is available again on PWSWeather.com. After
                                                                    sending reliable data associated with this new location, you station will
                                                                    be display again.
                                                                </Text>
                                                            )}
                                                        </Box>
                                                        <AddressEditor
                                                            mb={[4, null, null, 0]}
                                                            data={get(formData, 'location')}
                                                            onGeocode={handleGeocode}
                                                        />
                                                    </Col>
                                                </>
                                            )}
                                            <Col
                                                md={12}
                                                xxl={locationType === LocationType.Coordinate ? 12 : 6}
                                            >
                                                {locationType === LocationType.Coordinate && (
                                                    <Text
                                                        variant="caption1"
                                                        color="text.base.tertiary"
                                                        mb={4}
                                                    >
                                                        Provide the station latitude and longitude, or
                                                        center the map on your station&apos;s location
                                                        to have the coordinate values automatically
                                                        calculated.
                                                    </Text>
                                                )}
                                                <CoordinateEditor
                                                    coordinate={{
                                                        lat: isset(get(formData, 'location.latitude'))
                                                            ? parseFloat(
                                                                `${get(
                                                                    formData,
                                                                    'location.latitude',
                                                                )}`,
                                                            )
                                                            : null,
                                                        lon: isset(get(formData, 'location.longitude'))
                                                            ? parseFloat(
                                                                `${get(
                                                                    formData,
                                                                    'location.longitude',
                                                                )}`,
                                                            )
                                                            : null,
                                                    }}
                                                    allowEditingCoordinates={
                                                        locationType === LocationType.Coordinate
                                                    }
                                                    onMapBoundsChange={handleMapBoundsChange}
                                                    onElevationLookup={handleElevationLookup}
                                                />
                                            </Col>
                                        </>
                                    )}
                                </FormContext.Consumer>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Form>
            <LinkStationDialog $binding={$linkStationBinding} $davisLinkBinding={$davisLinkBinding} />
        </>
    );
};

export default ProfileView;
